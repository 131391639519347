import React, { useState } from 'react'
import Header from '../../components/CommonComponents/Header'
import { useNavigate } from 'react-router-dom'
import styles from "./Feedback.module.css"
import { Divider, Select, notification } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import {
    environmentData,
    EnvironmentData,
    EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import "./Feedback.module.css"
import { logAPIResponse } from '../../components/CommonUtils/utils/logs_Utils'


function Feedback() {
    let runEnv: keyof EnvironmentData =
        environmentData.run as keyof EnvironmentData;
    const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
    ] as EnvironmentConfig;

    const [name, setName] = useState<string>("")
    const [email, setEmail] = useState<string>("")
    const [applicationName, setApplicationName] = useState<string>("")
    const [feedbackMsg, setFeedbackMsg] = useState<string>("")

    const navigate = useNavigate()
    const options = [{ value: "General", label: "General" }, { value: "Customer counter", label: "Customer counter" },
    { value: "Smartphone", label: "Smartphone" }, { value: "Instore care", label: "Instore care" },
    { value: "Store Scanner MSS", label: "Store Scanner MSS" }, { value: "AEOS", label: "AEOS" },
    { value: "POS", label: "POS" }, { value: "Store notification", label: "Store notification" }
    ]

    const handleValidation = () => {
        let errorMessage = "";

        if (name?.trim()?.length < 3) {
            errorMessage += "Name should be minimum 3 characters.\n";
        }
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(email)) {
            errorMessage += "Invalid email.\n";
        }

        if (!applicationName) {
            errorMessage += "Select application.\n";
        }

        if (feedbackMsg?.trim()?.length < 3) {
            errorMessage += "Feedback Message should be atleast 3 characters.\n";
        }

        if (errorMessage) {
            alert(errorMessage.trim());
            return false;
        }
        return true
    }

    const handleSubmit =  /* istanbul ignore function */ async () => {
        const validationRes = handleValidation()
        /* istanbul ignore start */
        if (validationRes) {
            let token = await GetAccessToken();
            try {
                //   setLoading(true);
                const response = await fetch(
                    `${environmentConfig.chatApiUrl}/StoreNotification/SaveCustomerFeedback`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
                        },
                        body: JSON.stringify({ Name: name, Email: email, Category: applicationName, Message: feedbackMsg, Source: "web" }),
                    }
                );

                if (!response.ok) {
                    logAPIResponse([], `Failed to submit feedback`)
                }
                notification.success({
                    message: "Success",
                    description: "Feedback submitted successfully!",
                    placement: "top"
                });
            } catch (error) {
                logAPIResponse([], `Failed to submit feedback ${error}`)
            }
        }
        /* istanbul ignore stop */
    }

    return (
        <div>
            <Header pageTitle="Business Command Center" />
            <div className={styles.mainDiv}>
                <div className={styles.routePageTitleWrapper}>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ paddingLeft: "0px" }}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </a>

                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ textDecoration: "underline", cursor: "auto" }}
                    >
                        Feedback
                    </a>
                </div>
                <div className={styles.feedbackHeader}>
                    <h2 className={styles.feedbackTitle}>Feedback</h2>
                    <Divider
                        style={{
                            marginTop: "12px",
                            marginBottom: "30px",
                            height: "1px",
                            backgroundColor: "#cccccc",
                        }}
                    ></Divider>
                    <div >
                        <div >
                            <div className={styles.inputContainer}>
                                <input type="text" placeholder='Name' className={styles.inputStyle} style={{ marginRight: "20px" }}
                                    onChange={e => { setName(e?.target?.value) }}
                                />
                                <input type="email" placeholder='Email' autoComplete='email' className={styles.inputStyle} style={{ marginRight: "20px" }}
                                    onChange={e => { setEmail(e?.target?.value) }}
                                />
                                <Select
                                    className={`${styles.HeaderCombo} custom-select`}
                                    suffixIcon={<DownOutlined />}
                                    placeholder="Select Application"

                                    onChange={value => {
                                        setApplicationName(value)
                                    }}
                                    options={options}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "start" }}>
                                <textarea className={styles.msgInput} placeholder="Message" rows={5} id=""
                                    onChange={e => { setFeedbackMsg(e?.target?.value) }}

                                ></textarea>
                            </div>
                            <button className={styles.submitBtn} onClick={() => handleSubmit()}>Submit feedback</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Feedback