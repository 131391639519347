import React from "react";
import styles from "./TotalCountsCard.module.css";
interface GenericTotalCountsCard {
  label?: string;
  count?: number;
  borderColor?:string;
  innerBorderStyle?:string;
}
const TotalCountsCard: React.FC<GenericTotalCountsCard> = ({
  label,
  count,
  borderColor,
  innerBorderStyle
}) => {
  return (
    <div
      className={styles.mainContainer}
      style={{
        // background:
        //   label === "In Business hours"
        //     ? "linear-gradient(180deg, #000000 32.89%, #B9B9B9 107.89%)"
        //     : "",
        border:`2px solid ${borderColor?borderColor:"white"}`
      }}
    >
      <div
        className={styles.countTypographyWrapper}
        style={{
          
             border:innerBorderStyle
        }}
      >
        {/* {label !== "Stores with issues" ? ( */}
          <div
            className={styles.countTypography}
          >
            {count}
          </div>
        {/*  ) : (
           <div className={styles.textLabel}>Work In Progress</div>
         )*/}
      </div>
      <div
        className={styles.labelWrapper}
        style={{
          color: "#000000",
          margin: label==="Outside Business hours"?"10px":"10px 25px"
        }}
      >
        {label}
      </div>
    </div>
  );
};

export default TotalCountsCard;
