import React, { useEffect, useState } from "react";
import { Col, Row, Popover, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./SalesManagement.module.css";
import { CardSection } from "../../components/CommonComponents/CardSection";
import CustomTable from "../../components/CommonComponents/Table";
import {
    environmentData,
    EnvironmentData,
    EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import Header from "../../components/CommonComponents/Header";
import { logAPIResponse } from "../../components/CommonUtils/utils/logs_Utils";
import { FilterFilled } from "@ant-design/icons";

const SalesManagement = () => {
    let runEnv: keyof EnvironmentData =
        environmentData.run as keyof EnvironmentData;
    const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
    ] as EnvironmentConfig;
    const navigate = useNavigate();
    const [metricesData, setMetricesData] = useState<any>([]);
    const [applicationListData, setApplicationListData] = useState<any>([]);
    const [selectedApplicationDetails, setSelectedApplicationDetails] =
        useState<any>([]);
    const [showEditMsgDialog, setShowEditMsgDialog] = useState<boolean>(false);
    const [metricsLoading, setMetricsLoading] = useState<boolean>(false);
    const [showCountryFilter, setShowCountryFilter] = useState<boolean>(false);
    const [countryFilterVal, setCountryFilterVal] = useState<any>([]);
    const [selectedCountry, setSelectedCountry] = useState<any>([]);
    const [showStoreFilter, setShowStoreFilter] = useState<boolean>(false);
    const [storeFilterVal, setStoreFilterVal] = useState<any>([]);
    const [selectedStore, setSelectedStore] = useState<any>([]);

    const [showRemarksFilter, setShowRemarksFilter] = useState<boolean>(false);
    const [remarkFilterVal, setRemarkFilterVal] = useState<any>([]);
    const [selectedRemarks, setSelectedRemarks] = useState<any>([]);

    const [tableVal, setTableVal] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);


    const handleCountryFilter = () => {
        const countryVal = tableVal?.map((item: any) => item.countryCode)
        const uniqueCountry = [...new Set(countryVal)];
        setCountryFilterVal(uniqueCountry)
        setShowCountryFilter(!showCountryFilter)
    }


    const handleCountrySelection = (val: any) => {
        showStoreFilter && setShowStoreFilter(false)
        showRemarksFilter && setShowRemarksFilter(false)
        if (selectedCountry?.includes(val)) {
            const selectedCountries = selectedCountry?.filter((item: any) => item !== val)
            setSelectedCountry(selectedCountries)
            handleFilterBasedOnCountry(selectedCountries)
        } else {
            setSelectedCountry([...selectedCountry, val])
            handleFilterBasedOnCountry([...selectedCountry, val])
        }
    }

    const handleFilterBasedOnCountry = (selectedCountries: any) => {
        if (selectedCountries?.length === 0) {
            clearFilterBasedOnCountry()
        } else {
            // console.log("selectedCountries", selectedCountries)
            const filteredData = tableVal?.filter((item: any) => selectedCountries?.includes(item.countryCode))
            setTableData(filteredData)
        }
        filterStoreOptionsForSelectedCountry()
        setShowCountryFilter(false)
    }
    /* v8 ignore start */
    const clearFilterBasedOnCountry = () => {
        setSelectedCountry([])
        setTableData(tableVal)
        setSelectedStore([])
        setSelectedRemarks([])
        setShowCountryFilter(false)
        setShowStoreFilter(false)
        setShowRemarksFilter(false)
    }
    /* v8 ignore stop */
    const filterStoreOptions = () => {
        const storeVal = tableData?.map((item: any) => item.storeID)
        const uniqueStore = [...new Set(storeVal)];
        // console.log("uniqueStore", uniqueStore)
        setStoreFilterVal(uniqueStore)
    }
    const filterStoreOptionsForSelectedCountry = () => {
        let stores: any = []
        selectedCountry?.forEach((country: any) => {
            const storeVal = tableVal?.map((item: any) => {
                if (item?.storeID?.includes(country))
                    stores.push(item?.storeID)
            })
        })
        setStoreFilterVal([...stores])
        // console.log("stores", [...stores])
    }
    const handleStoreFilter = () => {
        selectedCountry?.length === 0 ? filterStoreOptions() : filterStoreOptionsForSelectedCountry()
        showCountryFilter && setShowCountryFilter(false)
        setShowStoreFilter(!showStoreFilter)
    }
    /* v8 ignore start */
    const handleStoreSelection = (val: any) => {
        showCountryFilter && setShowCountryFilter(false)
        showRemarksFilter && setShowRemarksFilter(false)
        if (selectedStore?.includes(val)) {
            const selectedStores = selectedStore?.filter((item: any) => item !== val)
            setSelectedStore(selectedStores)
            handleFilterBasedOnStore(selectedStores)
        } else {
            setSelectedStore([...selectedStore, val])
            handleFilterBasedOnStore([...selectedStore, val])
        }
    }
    /* v8 ignore stop */
    /* v8 ignore start */
    const handleFilterBasedOnStore = (selectedStores: any) => {
        if (selectedStores?.length === 0) {
            handleFilterBasedOnCountry(selectedCountry)
        } else {
            // console.log("selectedStores", selectedStores)
            const filteredData = tableVal?.filter((item: any) => selectedStores?.includes(item.storeID))
            setTableData(filteredData)
        }
        setShowStoreFilter(false)
    }
    /* v8 ignore stop */

    /* v8 ignore start */
    const clearFilterBasedOnStore = () => {
        setSelectedStore([])
        handleFilterBasedOnCountry(selectedCountry)
        setShowStoreFilter(false)
    }
    /* v8 ignore stop */
    /* v8 ignore start */
    const filterRemarkOptions = () => {
        const remarkVal = tableVal?.map((item: any) => item.remark)
        const uniqueRemark = [...new Set(remarkVal)];
        // console.log("uniqueRemark", uniqueRemark)
        setRemarkFilterVal(uniqueRemark)
    }
    /* v8 ignore stop */
    /* v8 ignore start */
    const handleRemarkFilter = () => {
        if (selectedCountry?.length === 0 && selectedStore?.length === 0) {
            filterRemarkOptions()
        } if (selectedCountry?.length > 0 && selectedStore?.length === 0) {
            const remarkVal = tableVal?.filter((item: any) => selectedCountry?.includes(item.countryCode))?.map((item: any) => item.remark)
            const uniqueRemark = [...new Set(remarkVal)];
            // console.log("uniqueRemark", uniqueRemark)
            setRemarkFilterVal(uniqueRemark)
        }
        if (selectedCountry?.length === 0 && selectedStore?.length > 0) {
            const remarkVal = tableVal?.filter((item: any) => selectedStore?.includes(item.storeID))?.map((item: any) => item.remark)
            const uniqueRemark = [...new Set(remarkVal)];
            // console.log("uniqueRemark", uniqueRemark)
            setRemarkFilterVal(uniqueRemark)
        }
        showStoreFilter && setShowStoreFilter(false)
        showCountryFilter && setShowCountryFilter(false)
        setShowRemarksFilter(!showRemarksFilter)
    }
    /* v8 ignore stop */
    /* v8 ignore start */
    const handleRemarkSelection = (val: any) => {
        if (selectedRemarks?.includes(val)) {
            const selectedRemarkVal = selectedRemarks?.filter((item: any) => item !== val)
            setSelectedRemarks(selectedRemarkVal)
            handleFilterBasedOnRemarks(selectedRemarkVal)
        } else {
            setSelectedRemarks([...selectedRemarks, val])
            handleFilterBasedOnRemarks([...selectedRemarks, val])
        }
    }
    /* v8 ignore stop */
    /* v8 ignore start */
    const handleFilterBasedOnRemarks = (selectedRemarks: any) => {
        if (selectedRemarks?.length === 0) {
            handleFilterBasedOnStore(selectedStore)
        } else {
            // console.log("selectedRemarks", selectedRemarks)
            const filteredData = tableVal?.filter((item: any) => {
                if (selectedCountry?.length > 0 && selectedStore?.length > 0) {
                    return selectedCountry?.includes(item.countryCode) && selectedStore?.includes(item.storeID) && selectedRemarks?.includes(item.remark)
                }
                if (selectedCountry?.length > 0 && selectedStore?.length === 0) {
                    return selectedCountry?.includes(item.countryCode) && selectedRemarks?.includes(item.remark)
                }
                if (selectedCountry?.length === 0 && selectedStore?.length > 0) {
                    return selectedStore?.includes(item.storeID) && selectedRemarks?.includes(item.remark)
                }
                if (selectedCountry?.length === 0 && selectedStore?.length === 0) {
                    return selectedRemarks?.includes(item.remark)
                }
            })
            setTableData(filteredData)
        }
        setShowRemarksFilter(false)
    }
    const clearFilterBasedOnRemarks = () => {
        setSelectedRemarks([])
        handleFilterBasedOnStore(selectedStore)
        setShowRemarksFilter(false)
    }
    /* v8 ignore stop */

    /* v8 ignore start */
    const handleModalClose = () => {
        setShowEditMsgDialog(false)
        setSelectedCountry([])
        setSelectedStore([])
        setSelectedRemarks([])
        setTableData(tableVal)
        showCountryFilter && setShowCountryFilter(false)
        showStoreFilter && setShowStoreFilter(false)
        showRemarksFilter && setShowRemarksFilter(false)
    }
    /* v8 ignore stop */

    const currentMeasureContent = (
        <div className={styles.currentMeasurePopupContainer}>
            <div className={styles.currentMeasurePopupHeading}>
                <div style={{ display: "flex", justifyContent: "center", fontSize: "14px", fontWeight: "bold", paddingTop: "4px" }}>
                    Deviation Details
                </div>
                <span
                    className={styles.closeIcon}
                    onClick={handleModalClose}
                ></span>
            </div>
            <div className={styles.tableContainer}>
                <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                    <div className={styles.countryHeading}><span>Country</span> <span className="custom-filter" onClick={handleCountryFilter}><FilterFilled /></span>
                        {showCountryFilter && <div className={styles?.filterOptionDiv}>{
                            countryFilterVal?.map((val: any, ind: any) => {
                                return <div className={styles.OptionsList} key={ind} style={{ borderBottom: `${ind < countryFilterVal?.length - 1 ? "1px solid gray" : ""}`, fontWeight: "normal" }}>
                                    <input type="checkbox" id={val} checked={selectedCountry?.includes(val)} style={{ marginRight: "5px" }} onChange={() => {
                                        handleCountrySelection(val)
                                    }} />
                                    <label htmlFor={val}>{val}</label>
                                </div>
                            })}
                            <div className={styles.filterOkDiv}>
                                <span className={styles.filterClearbtn} onClick={clearFilterBasedOnCountry}>Clear</span>
                                {/* <span className={styles.filterOkbtn} onClick={handleFilterBasedOnCountry}>Ok</span> */}
                            </div>
                        </div>}
                    </div>
                    <div className={styles.storeIdHeading}>Store ID <span className="custom-filter" onClick={handleStoreFilter}><FilterFilled /></span>
                        {showStoreFilter && <div className={styles.filterOptionDiv}>{
                            storeFilterVal?.map((val: any, ind: any) => {
                                return <div className={styles.OptionsList} key={ind} style={{ borderBottom: `${ind < storeFilterVal?.length - 1 ? "1px solid gray" : ""}`, fontWeight: "normal" }}>
                                    <input type="checkbox" id={val} checked={selectedStore?.includes(val)} style={{ marginRight: "5px" }} onChange={() => {
                                        handleStoreSelection(val)
                                    }} />
                                    <label htmlFor={val}>{val}</label>
                                </div>
                            })}
                            <div className={styles.filterOkDiv}>
                                <span className={styles.filterClearbtn} onClick={clearFilterBasedOnStore}>Clear</span>
                                {/* <span className={styles.filterOkbtn} onClick={handleFilterBasedOnStore}>Ok</span> */}
                            </div>
                        </div>}
                    </div>
                    <div className={styles.remarksHeading}>Remarks <span className="custom-filter" onClick={handleRemarkFilter}><FilterFilled /></span>
                        {showRemarksFilter && <div className={styles.filterOptionDiv}>{
                            remarkFilterVal?.map((val: any, ind: any) => {
                                return <div className={styles.OptionsList} key={ind} style={{ borderBottom: `${ind < remarkFilterVal?.length - 1 ? "1px solid gray" : ""}`, fontWeight: "normal" }}>
                                    <input type="checkbox" id={val} checked={selectedRemarks?.includes(val)} style={{ marginRight: "5px" }} onChange={() => {
                                        handleRemarkSelection(val)
                                    }} />
                                    <label htmlFor={val}>{val}</label>
                                </div>
                            })}
                            <div className={styles.filterOkDiv}>
                                <span className={styles.filterClearbtn} onClick={clearFilterBasedOnRemarks}>Clear</span>
                                {/* <span className={styles.filterOkbtn} onClick={handleFilterBasedOnStore}>Ok</span> */}
                            </div>
                        </div>}
                    </div>
                </div>
                <div className={styles.deviationDetailsContainer}>
                    {/* {currentMeasureData?.map((item: any, index: number) => { */}
                    {tableData?.map((item: any, index: number) => {
                        return (
                            <div key={index} style={{ display: "flex", justifyContent: "center", width: "100%", borderBottom: "1px solid gray" }}>
                                <span className={styles.country}>{item?.countryCode}</span>
                                <span className={styles.storeId} >{item?.storeID}</span>
                                <span className={styles.remarks}>{item?.remark}</span>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    )


    const appList = [
        {
            "applicationID": 1,
            "applicationName": "STORE BUSINESS HOURS",
            "alertColor": "Green",
            "alertCount": "0"
        }
    ]

    const columns = [
        {
            title: "METRICS",
            dataIndex: "metrics",
            applicationId: "",
            width: 170,
        },
        {
            title: "METRICS NAME",
            dataIndex: "metricName",
            applicationId: "metricName",
            width: 250,
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: "CONFIGURABLE THRESHHOLD",
            dataIndex: "configurableThreshold",
            applicationId: "configurableThreshold",
            width: 220,
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: "CURRENT MEASURE",
            dataIndex: "currentMeasure",
            applicationId: "currentMeasure",
            width: 150,
            render: (text: any) => <div style={{ position: "relative" }}>
                <Popover
                    placement="right"
                    trigger="click"
                    title={''}
                    content={currentMeasureContent}
                    open={showEditMsgDialog}
                    style={{ position: "absolute", left: "0px" }}

                >
                    <span style={{ color: "#1E950B", cursor: "pointer" }} onClick={showEditMsgDialog ? () => setShowEditMsgDialog(false) : () => setShowEditMsgDialog(true)} >{text}</span>
                </Popover></div>,
        },
        {
            title: "MEASURE TYPE",
            dataIndex: "measureType",
            applicationId: "measureType",
            width: 150,
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: "CONFIGURATION LEVEL",
            dataIndex: "configurationLevel",
            applicationId: "configurationLevel",
            width: 170,
            render: (text: any) => <span>{text}</span>,
        },
        {
            title: "MAPPED TO APPLICATION HEALTH",
            dataIndex: "mappedToApplicationHealth",
            applicationId: "mappedToApplicationHealth",
            width: 250,
            render: (text: any) => <span>{text}</span>,
        },
    ];

    const fetchGetApplicationMetrices = async () => {
        const params = new URLSearchParams();
        setMetricsLoading(true)
        try {
            let token = await GetAccessToken();
            const response = await fetch(
                `${environmentConfig.chatApiUrl}/StoreReport/GetSalesDataIntegrityDetails`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                setMetricsLoading(false)
                logAPIResponse([], `Failed to fetch sales managemnt data, /StoreReport/GetSalesDataIntegrityDetails.`)
            }
            setMetricsLoading(false)
            const data: any = await response.json();
            setMetricesData([data]);
            setTableData(data?.storeDeviations)
            setTableVal(data?.storeDeviations)
        } catch (error) {
            setMetricsLoading(false)
            logAPIResponse([], `Failed to fetch sales managemnt data, /StoreReport/GetSalesDataIntegrityDetails.${error}`)
        }
    };

    useEffect(() => {
        fetchGetApplicationMetrices();
    }, []);

    const handleCardClick = (value?: any) => {
        setSelectedApplicationDetails([value]);
    };

    useEffect(() => {
        const newData = appList?.map((item: any) => ({ id: item?.applicationID, title: item?.applicationName, color: item?.alertColor, count: item?.alertCount }))
        setApplicationListData(newData)
    }, [])

    return (
        <div>
            <Header pageTitle="Business Command Center" />

            <div className={styles.mainDiv}>
                <div className={styles.routePageTitleWrapper}>
                    <a className={styles.breadCrumbsLink} style={{ paddingLeft: "0px" }} onClick={() => navigate("/")}>Home</a>
                    <a>&gt;</a>
                    <a className={styles.breadCrumbsLink} style={{ textDecoration: "underline", cursor: "auto" }}>Sales Management</a>
                </div>
                <div className={styles.pageTitle}>Sales Management</div>
                <div className={styles.devider} />
                <div
                    style={{
                        marginBottom: "15px",
                        width: "100%",
                    }}
                >
                    <Row gutter={[16, 16]} >
                        {applicationListData?.map((item: any) => {
                            return (
                                <Col xs={24} sm={12} md={8} lg={6} key={item.applicationID}>
                                    <div style={{ width: "auto", cursor: "pointer" }}>
                                        <CardSection handleClick={handleCardClick} item={item} />
                                    </div>
                                </Col>
                            );
                        })}
                    </Row>
                </div>
                {metricsLoading ? <div className={styles.spinnerContainer}><Spin size="large" className="custom-spin" /></div> : <div>
                    <CustomTable columns={columns}
                        data={metricesData}
                    />
                </div>}
            </div>
        </div>
    );
};
export default SalesManagement;
