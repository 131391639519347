import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";

import { initializeIcons } from "@fluentui/react";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from "./config/MsalConfig";
import "./assets/scss/_custom.scss";
import NoPage from "./pages/NoPage";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import { MsalProvider } from "@azure/msal-react";
import Chat from "./pages/chat/Chat";
import { AppProvider } from "./components/ContextAndProvider/Provider";
import Network from "./pages/Network/Network";
import Reports from "./pages/Reports/Reports";
import CustomerCounter from "./pages/Reports/CustomerCounter";
import StoreNotification from "./pages/storenotification/StoreNotification";
import ApplicationHealth from "./pages/applicationhealth/ApplicationHealth";
import StoreSmartPhone from "./pages/storesmartphone/StoreSmartPhone";
import InstoreCustCareProd from "./pages/instorecustcareprod/InstoreCustCareProd";
import StoreScannerSystems from "./pages/StoreScannerSystems/StoreScannerSystems";
import MetricsList from "./pages/metricsList/MetricsList";
import Feedback from "./pages/feedback/Feedback";
import Aeos from "./pages/aeos/Aeos";
import Pos from "./pages/pos/Pos";
import StoreConfiguration from "./pages/configuration/StoreConfiguration";
import PrivateRoute from "./services/PrivateRoute";
import AssetInsights from "./pages/assetInsights/AssetInsights";
import SalesManagement from "./pages/salesManagement/SalesManagement";
import StoreWithIssues from "./pages/storewithissue/StoreWithIssues";
import {
  environmentData
} from "./environments/env";
import MaintenancePage from "./pages/maintenancePage/MaintenancePage";
import MusicInStore from "./pages/musicInStore/MusicInStore";
export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (
  !msalInstance.getActiveAccount() &&
  msalInstance.getAllAccounts().length > 0
) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
}

msalInstance.addEventCallback((event) => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    if (event.payload && "account" in event.payload) {
      const account = event.payload.account;
      if (account) {
        msalInstance.setActiveAccount(account);
      }
    }
  }
});

initializeIcons();

export default function App() {
  const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth <= 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <MsalProvider instance={msalInstance}>
      <HashRouter>
        <Routes>
          <Route
            path="/chat"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <Chat />
              </PrivateRoute>
            }
          />
          <Route
            path="/network"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <Network />
              </PrivateRoute>
            }
          />
          <Route
            path="/storenotification"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <StoreNotification />
              </PrivateRoute>
            }
          />
          <Route
            path="/applicationhealth"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <ApplicationHealth />
              </PrivateRoute>
            }
          />
          <Route
            path="/STORESMARTPHONE/:countries?/:storeId?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <StoreSmartPhone />
              </PrivateRoute>
            }
          />
          <Route
            path="/INSTORECUSTCARE-PROD"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <InstoreCustCareProd />
              </PrivateRoute>
            }
          />
          <Route
            path="/reports"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <Reports />
              </PrivateRoute>
            }
          />
          <Route
            path="/metricslist"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <MetricsList />
              </PrivateRoute>
            }
          />
          <Route
            path="/customer-counter/:reportDate?/:countries?/:storeId?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <CustomerCounter />
              </PrivateRoute>
            }
          />
          <Route
            path="/store-scanner-systems/:brands?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <StoreScannerSystems />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Login />}>
            <Route
              index
              element={
                <PrivateRoute role={["Admin", "Users"]}>
                  <Home />
                </PrivateRoute>
              }
            />
            <Route
              path="*"
              element={
                <PrivateRoute role={["Admin", "Users"]}>
                  <NoPage />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/feedback"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <Feedback />
              </PrivateRoute>
            }
          />
          <Route
            path="/aeos/:countries?/:storeId?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <Aeos />
              </PrivateRoute>
            }
          />
          <Route
            path="/pos/:countries?/:storeId?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <Pos />
              </PrivateRoute>
            }
          />
          <Route
            path="/store-configuration"
            element={
              <PrivateRoute role={["Admin"]}>
                <StoreConfiguration />
              </PrivateRoute>
            }
          />
          <Route
            path="/storeSmartphone/assetInsights/:brands?/:countries?/:storeId?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <AssetInsights />
              </PrivateRoute>
            }
          />

          <Route
            path="/salesmanagement"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <SalesManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/storewithissues"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <StoreWithIssues />
              </PrivateRoute>
            }
          />
          <Route
            path="/music-in-store/:brands?/:countries?/:storeId?"
            element={
              <PrivateRoute role={["Admin", "Users"]}>
                <MusicInStore />
              </PrivateRoute>
            }
          />
        </Routes>
      </HashRouter>
    </MsalProvider>
  );
}
async function startMocking() {
  if (window?.location?.hostname === 'localhost' && environmentData?.run === "development") {
    await import("./mocks/browser")
      .then(({ worker }) => worker.start({ onUnhandledRequest: 'bypass' }))
      .catch((err) => console.error("MSW failed to start", err));
  }
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <AppProvider>
        <App />
      </AppProvider>
    </React.StrictMode>
  );
}

startMocking();