export interface EnvironmentData {
  run: keyof typeof environmentData;
  development: EnvironmentConfig;
  staging: EnvironmentConfig;
  production: EnvironmentConfig;
}

export interface EnvironmentConfig {
  clientId: string;
  authority: string;
  tenantId: string;
  "api-scope": string;
  appInsightConnectionString: string;
  gpt3key: string;
  gpt4key: string;
  chatApiUrl?: string;
  hapiSubscriptionKey: string
}
export const environmentData: any = {
  run: "development",

  development: {
    clientId: "b24cb888-21d8-468a-b12d-c6215c405eb9",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope": "b24cb888-21d8-468a-b12d-c6215c405eb9/.default",
    appInsightConnectionString:
      "InstrumentationKey=75380002-f938-4d26-bd78-b090d2d0b8ee;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=b6021bfb-9c96-47e6-ac9e-4ce8be3bc6dc",
    // "InstrumentationKey=59459088-55d4-4c26-ad97-fa5503e896aa;IngestionEndpoint=https://swedencentral-0.in.applicationinsights.azure.com/;ApplicationId=e8cda839-8cc9-461e-84d0-1237eddcea1c",
    gpt3key: "gpt-35-turbo-test",
    gpt4key: "gpt-4-turbo-test",
    hapiSubscriptionKey: "c628bb71e55845dea8cc52dffb83313f",
    chatApiUrl: "https://aps-storeiservice-core-api-dev.azurewebsites.net"
  },
  staging: {
    clientId: "b24cb888-21d8-468a-b12d-c6215c405eb9",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope": "b24cb888-21d8-468a-b12d-c6215c405eb9/.default",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-test",
    gpt4key: "gpt-4-turbo-test",
    hapiSubscriptionKey: "c628bb71e55845dea8cc52dffb83313f",
    chatApiUrl: "https://aps-storeiservice-core-api-dev.azurewebsites.net"
  },
  production: {
    clientId: "060c6088-161d-4639-8603-9ee185e64e1b",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid 060c6088-161d-4639-8603-9ee185e64e1b/.default",
    appInsightConnectionString:
      "InstrumentationKey=132d399a-4e05-4699-9d5b-be16f4ff6818;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/;ApplicationId=5fe4dc3c-8e20-4459-bc37-d131c94ce02f",
    // "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt35_8K",
    gpt4key: "gpt-4-turbo",
    hapiSubscriptionKey: "11681e19ce0d4856a4a7b534e3cb9cd6",
    chatApiUrl: "https://storeiserviceapi.hmgroup.com"
  },
} as EnvironmentData;
