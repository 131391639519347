import React, { useEffect, useState } from "react";
import { Divider, Tooltip } from "antd";
import "../assets/scss/_custom.scss";
import styles from "./AppHeaderMobile.module.css";
import {
  CNavItem,
  CNavLink,
  CContainer,
  CHeader,
  CHeaderNav,
  CAvatar,
  CPopover,
  CRow,
  CCol,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CFormCheck,
  CTooltip,
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
// import AppBreadcrumb from "./AppBreadcrumb";
import appLogo from "../assets/brand/hm-logo.png";

import { useMsal } from "@azure/msal-react";
import "../assets/scss/_headertitle.scss";
// import { getAppInsights } from "../services/TelemetryService";
import { getInitials } from "./CommonUtils/CommonUtils";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../environments/env";
import { useAppContext } from "./ContextAndProvider/Context";
import { GetRoles } from '../services/AuthUser';
import { Link } from "react-router-dom";
interface AppHeaderProps {
  userDetails: any[];
}

const AppHeaderMobile: React.FC<AppHeaderProps> = ({ userDetails }) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  // let appInsights

  // useEffect(() => {
  //   appInsights = getAppInsights()
  // }, [])

  const handleLogout = (logoutType: string) => {
    if (logoutType === "redirect") {
      instance.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
      sessionStorage.clear();
      localStorage.clear();
      instance.logoutRedirect();
    }
  };
  const { modelValue, setModelValue } = useAppContext(); // Default selected option
  const handleOptionChange = (option: any) => {
    setModelValue(option);
  };

  // console.log("User name is = ",getInitials(userDetails?.[0]?.userName))

  const [showTool, setShowTool] = useState(false);

  return (
    <CHeader
      onMouseEnter={() => {
        setShowTool(true);
      }}
      onMouseLeave={() => {
        setShowTool(false);
      }}
    >
      <CContainer fluid className="px-0">
        <div
          style={{ paddingLeft: "20px", display: "flex", alignItems: "center" }}
        >
          <span className="headerlogo"></span>
          <Divider
            type="vertical"
            style={{
              backgroundColor: "white",
              height: "19px",
              margin: "0px 12px",
            }}
          ></Divider>
          <span className={styles.headingSubPart}>
            Operations <span style={{ color: "red" }}>i</span>Services
          </span>
        </div>
        <div>
          <CHeaderNav>
            <CNavItem></CNavItem>
          </CHeaderNav>
          <CHeaderNav className={styles.iconsDiv}>
            {GetRoles()?.includes("Admin") && <CNavItem>
              <CNavLink
               
                onClick={() => navigate("/store-configuration")}
                data-testid="settingIcon"
              >
                <CAvatar  className="circleIcon">
                  <span className="settingSpriteIcon"></span>
                </CAvatar>
              </CNavLink>
            </CNavItem>}
            <CNavItem
              className="d-flex align-items-center "
              onMouseEnter={() => {
                setShowTool(true);
              }}
              onMouseLeave={() => {
                setShowTool(false);
              }}
            >
              {/* <Tooltip placement="bottom" color="black" title={userDetails?.[0]?.userName} > */}
              <div className={styles.userNameTooltip}>
                <span className={styles.userNameTooltiptext}>
                  {userDetails?.[0]?.userName}
                </span>
                <CAvatar
                  className="profileAvatar"
                  data-coreui-toggle="tooltip"
                  data-coreui-placement="bottom"
                  // title={userDetails?.[0]?.userName}
                  textColor="white"
                  data-testid="userName"
                  role="button"
                >
                  {userDetails.length > 0 &&
                    getInitials(userDetails?.[0]?.userName)}
                </CAvatar>
              </div>
              {/* </Tooltip> */}
            </CNavItem>
            <Divider
              type="vertical"
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                backgroundColor: "white",
                height: "21px",
              }}
            />

            <CNavItem>
              <CNavLink
                onClick={() => handleLogout("redirect")}
                data-testid="logoutIcon"
                style={{ paddingLeft: "0px" }}
              >
                {/* <Tooltip placement="bottom" color="black" title="Logout"> */}
                <div className={styles.tooltip}>
                  <span className={styles.tooltiptext}>Logout</span>
                  <CAvatar className="circleIcon">
                    <span className="logoutSpriteIcon"></span>
                  </CAvatar>
                </div>
                {/* </Tooltip> */}
              </CNavLink>
            </CNavItem>
          </CHeaderNav>
        </div>
      </CContainer>
    </CHeader>
  );
};

export default AppHeaderMobile;
