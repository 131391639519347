import React, { useEffect, useState } from 'react'
import Header from '../../components/CommonComponents/Header'
import styles from './MusicInStore.module.css'
import { useNavigate } from 'react-router-dom'
import DropDownList from '../../components/CommonComponents/DropDownList'
import { Checkbox, Divider, Spin } from 'antd'
import CustomTable from '../../components/CommonComponents/Table'
import { GetAccessToken } from '../../services/AuthToken'
import {
    environmentData,
    EnvironmentData,
    EnvironmentConfig,
} from "../../environments/env";
import { logAPIResponse } from '../../components/CommonUtils/utils/logs_Utils'
import { GetLoggedInCountry } from '../../services/AuthUser'
import type { CheckboxProps, CollapseProps, GetProp } from "antd";
import queryString from 'query-string'

const CheckboxGroup = Checkbox.Group;


function MusicInStore() {
    let runEnv: keyof EnvironmentData =
        environmentData.run as keyof EnvironmentData;
    const environmentConfig: EnvironmentConfig = environmentData[
        runEnv
    ] as EnvironmentConfig;

    const navigate = useNavigate()
    const [countryOptions, setCountryOptions] = useState<any | null>([]);
    const [defaultSelectedCountryId, setDefaultSelectedCountryId] = useState<any>(
        []
    );
    const [defaultStores, setDefaultStores] = useState<any>([]);
    const [showIpAddressList, setShowIpAddressList] = useState<any>([]);
    const [loading, setLoading] = useState(false); // New loading state
    const [storesLoading, setStoresLoading] = useState(false); // New loading state
    const [selectedCountry, setSelectedCountry] = useState<any | null>([]);
    const [displaySelectedCountry, setDisplaySelectedCountry] = useState<any>("");
    const [displaySelectedStore, setDisplaySelectedStore] = useState<any>("");
    const [musicInStoreData, setMusicInStoreData] = useState<any>([]);
    const [selectedStore, setSelectedStore] = useState<any | null>([]);
    const [storeOptions, setStoresOptions] = useState<any>([]);
    const [checkedList, setCheckedList] = useState<any>([]);
    const [brandAndCountryStores, setbrandAndCountryStores] = useState<any>("");
    const [brandAndStores, setbrandAndStores] = useState<any>([]);
    const [countryOptionsCopy, setCountryOptionsCopy] = useState<any | null>([]);
    const [brandAndCountries, setbrandAndCountries] = useState<any>("");
    const [brandStoreResponse, setBrandStoreResponse] = useState<string[]>([]);

    const plainOptions = [
        { label: "H&M", value: "H&M" },
        { label: "COS", value: "COS" },
        { label: "Weekday", value: "Weekday" },
        { label: "Monki", value: "Monki" },
        { label: "Other Stories", value: "Stories" },
        { label: "Arket", value: "Arket" },
    ];

    const checkAll = plainOptions?.length === checkedList?.length;

    const handleShowIpAddress = (record: any) => {
        setShowIpAddressList([
            ...showIpAddressList,
            { ipAddress: record?.ipAddress, deviceName: record?.associatedDevice },
        ]);
    };

    const handleHideIpAddress = (record: any) => {
        setShowIpAddressList(
            showIpAddressList?.filter(
                (val: any) => val?.ipAddress !== record?.ipAddress && val?.deviceName !== record?.associatedDevice
            )
        );
    };

    const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
        if (e.target.checked) {
            const selectedCountryWithoutAll = countryOptions?.filter(
                (val: any) => val !== "All"
            );
            setCountryOptions(selectedCountryWithoutAll);
        }
        setCountryOptions(countryOptionsCopy);
        setCheckedList(
            e.target.checked ? plainOptions.map((option) => option.value) : []
        );
        if (!e.target.checked) {
            handleClearCountry();
        }
    };

    const handleRemoveCountryStores = (removedBrand: any, updatedList: any) => {
        // unchecking countries of unselected brand only the country is not present for other brands
        const removedBrandCountryObj = brandAndCountries?.find(
            (val: any) => val?.brand == removedBrand
        );
        const removedBrandCountries = removedBrandCountryObj?.countries;
        const selectedCountriesInRemovedBrand = selectedCountry?.filter(
            (val: any) => removedBrandCountries?.includes(val)
        );
        const countryObj = brandAndCountries?.filter((val: any) => {
            return updatedList?.includes(val?.brand);
        });

        const updatedBrandsCountries = countryObj
            ?.map((val: any) => {
                return val?.countries;
            })
            .flat();

        const countryTobeUnchecked = selectedCountry?.filter(
            (val: any) => !updatedBrandsCountries?.includes(val)
        );
        const filteredCountries = selectedCountry?.filter(
            (val: any) => !countryTobeUnchecked?.includes(val)
        );

        setSelectedCountry(filteredCountries);
        setDisplaySelectedCountry(filteredCountries?.join(", "));

        const removedBrandStore = brandAndStores?.filter(
            (val: any) => val?.brand === removedBrand
        );
        const removedStores = removedBrandStore[0]?.stores;

        const filteredSelectedStore = selectedStore?.filter(
            (val: any) => !removedStores?.includes(val)
        );

        setSelectedStore(filteredSelectedStore);
        setDisplaySelectedStore(filteredSelectedStore?.join(", "));

        const filteredStores = storeOptions.filter(
            (obj1: any) => !removedStores.some((obj2: any) => obj1.key === obj2)
        );
        // console.log("filteredStore = ", filteredStores)

        setStoresOptions(filteredStores);
    };

    const handleSetCountryStores = (updatedBrand: any) => {
        let countryStores: any = selectedCountry?.map((selectedCountry: any) => {
            return brandAndCountryStores?.filter(
                (val: any) =>
                    val.country === selectedCountry && val?.brand === updatedBrand
            );
        });
        let stores: any = [];
        countryStores.forEach((val: any) => {
            val[0]?.stores?.forEach((val: any) => {
                stores.push({
                    key: val,
                    value: val,
                    checked: false,
                });
            });
        });
        // console.log("stores = ", stores)

        if (selectedCountry?.includes("All")) {
            const selectedCountryWithoutAll = selectedCountry?.filter(
                (val: any) => val !== "All"
            );
            setSelectedCountry(selectedCountryWithoutAll);
            setDisplaySelectedCountry(selectedCountryWithoutAll?.join(", "));
        }

        setStoresOptions((prev: any) => [...prev, ...stores]);
    };

    const onCheckBoxChange = (list: any[]) => {
        if (list?.length > 0) {
            if (list?.length > checkedList?.length) {
                // console.log("list", list)
                const updatedBrand = list
                    ?.filter((val: any) => !checkedList?.includes(val))
                    ?.toString();
                handleSetCountryStores(updatedBrand);
                const splittedBrand = list?.join(",");

                setCheckedList(list);
            }
            if (checkedList?.length > list?.length) {
                // console.log("list", checkedList, list)

                const removedBrand = checkedList?.filter(
                    (val: any) => !list?.includes(val)
                );
                handleRemoveCountryStores(removedBrand.toString(), list);
                const splittedBrand = list?.join(",");
                setCheckedList(list);
            }
        }
        if (list?.length === 0) {
            setCheckedList([]);
            handleClearCountry();
        }
    };

    const handleSetStores = (addedCountry: string[]) => {
        if (!addedCountry?.includes("All")) {
            let stores: any = []
            addedCountry?.forEach((country: any) => {
                const countryStores = brandAndCountryStores?.filter((val: any) => val.country === country)
                const filterOnSelectedBrands = countryStores?.filter((val: any) => checkedList?.includes(val?.brand))
                // console.log("countryStores = ", filterOnSelectedBrands)
                // let stores: any = []
                filterOnSelectedBrands.forEach((filteredVal: any) => {
                    filteredVal?.stores?.forEach((val: any) => {
                        stores.push({
                            key: val,
                            value: val,
                            checked: false
                        })
                    })
                })
            })
            if (selectedStore?.includes("All")) {
                const selectedStoresWithoutAll = selectedStore?.filter(
                    (val: any) => val !== "All"
                );
                setSelectedStore(selectedStoresWithoutAll);
                setDisplaySelectedStore(selectedStoresWithoutAll?.join(", "));
            }
            setStoresOptions((prev: any) => [...prev, ...stores]);
            // console.log("stores = ", stores)
        } else {
            const selectedBrandAndStore = brandAndStores?.filter((val: any) =>
                checkedList?.includes(val.brand)
            );
            let selectedBrandAllStores: any = [];
            selectedBrandAndStore?.forEach((brandAndStore: any) =>
                selectedBrandAllStores?.push(...brandAndStore?.stores?.flat())
            );
            // console.log("selectedBrandAllStores", selectedBrandAllStores)

            if (storeOptions?.length > 0) {
                let existingStoreOptions: any = storeOptions?.map(
                    (storeOption: any) => storeOption?.key
                );
                const filteredStores = selectedBrandAllStores
                    ?.filter((val: any) => !existingStoreOptions?.includes(val))
                    ?.map((filteredStore: any) => {
                        return {
                            key: filteredStore,
                            value: filteredStore,
                            checked: false,
                        };
                    });
                // console.log("existingStoreOptions", existingStoreOptions)
                // console.log("filteredStores", filteredStores)
                setStoresOptions([...storeOptions, ...filteredStores]);
            } else {
                const stores = selectedBrandAllStores?.map((filteredStore: any) => {
                    return {
                        key: filteredStore,
                        value: filteredStore,
                        checked: false,
                    };
                });
                setStoresOptions(stores);
            }
        }
    };

    const handleRemoveStores = (removedCountry: string) => {
        const countryStores = brandAndCountryStores?.filter(
            (val: any) => val.country === removedCountry
        );
        const filterOnSelectedBrands = countryStores?.filter((val: any) =>
            checkedList?.includes(val?.brand)
        );
        // console.log("countryStores = ", filterOnSelectedBrands)
        let stores: any = [];
        filterOnSelectedBrands.forEach((filteredVal: any) => {
            filteredVal?.stores?.forEach((val: any) => {
                stores.push({
                    key: val,
                    value: val,
                    checked: false,
                });
            });
        });
        const removedStores = stores?.map((val: any) => val.key);
        const filteredSelectedStore = selectedStore?.filter(
            (val: any) => !removedStores?.includes(val)
        );
        setSelectedStore(filteredSelectedStore);
        setDisplaySelectedStore(filteredSelectedStore?.join(", "));

        const filteredStores = storeOptions.filter(
            (obj1: any) => !stores.some((obj2: any) => obj1.key === obj2.key)
        );

        setStoresOptions(filteredStores);
        // console.log("storeOptions stores", storeOptions)
        // console.log(" stores", stores)
        // console.log("remove stores", removedStores)
    };

    const handleCountryOnchange = (updatedItems: any[]) => {

        if (updatedItems?.length > 0) {
            if (updatedItems?.length > selectedCountry?.length) {
                // const addedCountry = updatedItems[updatedItems?.length - 1]
                const addedCountry = updatedItems?.filter(
                    (val: any) => !selectedCountry?.includes(val)
                );
                // console.log("updated country = ", addedCountry)
                handleSetStores(addedCountry);
                setSelectedCountry([...updatedItems]);
                setDisplaySelectedCountry(updatedItems.join(", "));
            } else {
                const removedCountry = selectedCountry.filter(
                    (val: any) => !updatedItems?.includes(val)
                );
                handleRemoveStores(removedCountry.toString());
                if (updatedItems?.includes("All")) {
                    const updateditemsWithoutAll = updatedItems?.filter(
                        (val: any) => val !== "All"
                    );
                    setSelectedCountry([...updateditemsWithoutAll]);
                    setDisplaySelectedCountry(updateditemsWithoutAll.join(", "));
                } else {
                    setSelectedCountry([...updatedItems]);
                    setDisplaySelectedCountry(updatedItems.join(", "));
                }
            }
        } else {
            setSelectedStore([]);
            setDisplaySelectedStore("");
            handleClearCountry();
        }
    };


    const handleClearCountry = async () => {
        setSelectedStore([]);
        setDisplaySelectedStore("");
        setStoresOptions([]);
        setSelectedCountry([]);
        setDisplaySelectedCountry("");
        setMusicInStoreData([]);

        const params = new URLSearchParams();
        params.set("countries", "");
        params.set("storeId", "");
        navigate({ search: params.toString() }, { replace: true });
    };

    const handleStoreOnchange = (
        updatedItems: any[]
    ) => {
        if (updatedItems?.length === 0) {
            handleUnSelectStore();
        } else {
            if (
                updatedItems?.includes("All") &&
                updatedItems?.length < selectedStore?.length
            ) {
                const selectedStoresWithoutAll = updatedItems?.filter(
                    (val: any) => val !== "All"
                );
                setSelectedStore([...selectedStoresWithoutAll]);
                setDisplaySelectedStore(selectedStoresWithoutAll.join(", "));
            } else {
                setSelectedStore([...updatedItems]);
                setDisplaySelectedStore(updatedItems.join(", "));
            }
        }
    };

    const handleUnSelectStore = () => {
        setDefaultStores([]);
        setMusicInStoreData([]);
    };

    const handleBrandCountryAndBrandStores = () => {
        const brandStores: any = [];
        const brandCountries: any = [];
        const brandCountrieStores: any = [];
        brandStoreResponse?.forEach((val: any) => {
            val?.storeCountries?.forEach((storeCountry: any) => {
                if (storeCountry?.stores?.length > 0) {
                    brandCountrieStores.push({
                        brand: val?.brand,
                        country: storeCountry?.countryName,
                        stores: storeCountry?.stores?.map((store: any) => store?.storeId),
                    });
                }
            });
            setbrandAndCountryStores(brandCountrieStores);

            brandCountries.push({
                brand: val?.brand,
                countries: val?.storeCountries?.map(
                    (storeCounty: any) => { if (storeCounty?.stores?.length > 0) return storeCounty?.countryName }
                ),
            });
            // setbrandAndCountries(brandAndCountries)
            setbrandAndCountries(brandCountries);
            brandStores.push({
                brand: val?.brand,
                stores: val?.storeCountries
                    ?.map((storeCounty: any) => {
                        if (storeCounty?.stores?.length > 0) { return storeCounty?.stores?.map((store: any) => store?.storeId); }
                    })
                    .flat(),
            });
            setbrandAndStores(brandStores);
        });
        // console.log("brand stores = ", brandStores)
        // console.log("brand country stores = ", brandCountrieStores)
    };

    const columns = [
        {
            title: "STORE ID",
            dataIndex: "site",
            key: "site",
            width: 150,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "Device Name",
            dataIndex: "associatedDevice",
            key: "associatedDevice",
            width: 150,
            render: (text: any) => <a>{text ? text : "Not Available"}</a>,
        },
        {
            title: "IP ADDRESS",
            dataIndex: "ipAddress",
            key: "ipAddress",
            width: 150,
            render: (text: any, record: any) => (
                <a>
                    {
                        showIpAddressList?.find((val: any) => {
                            return val?.ipAddress === text && val?.deviceName === record?.associatedDevice;
                        }) ? (
                            <a
                                onClick={() => {
                                    handleHideIpAddress(record);
                                }}
                            >
                                {text && text?.length !== 0 ? text : "Data not Available"}
                            </a>
                        ) : (
                            <span
                                className={styles.eyeIcon}
                                onClick={() => {
                                    handleShowIpAddress(record);
                                }}
                            ></span>
                        )
                    }
                </a>
            ),
        },
        {
            title: "MAC ID",
            dataIndex: "macAddr",
            key: "macAddr",
            width: 150,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            width: 150,
            filters: [
                {
                    text: "Connected",
                    value: "Connected",
                },
                {
                    text: "Not Connected",
                    value: "Not Connected",
                },
            ],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }: any) => (
                <div style={{ padding: 8, display: "flex", flexDirection: "column" }}>
                    {[
                        { text: "Connected", value: "Connected" },
                        { text: "Not Connected", value: "Not Connected" },
                    ].map((filter) => (
                        <Checkbox
                            key={filter.value}
                            style={{ padding: "5px" }}
                            onChange={(e) => {
                                const newSelectedKeys = e.target.checked
                                    ? [...selectedKeys, filter.value]
                                    : selectedKeys.filter((key: any) => key !== filter.value);
                                setSelectedKeys(newSelectedKeys);
                                confirm(); // Automatically apply the filter
                            }}
                            checked={selectedKeys.includes(filter.value)}
                        >
                            {filter.text}
                        </Checkbox>
                    ))}
                </div>
            ),
            filterIcon: () => <div className={styles.filterIcon} data-testid="filterOnlineOffline"></div>,
            onFilter: (value: any, record: any) =>
                record?.status === value,
            // sorter: (a: any, b: any) => a.statusMessage.localeCompare(b.statusMessage),

            sorter: (a: any, b: any) => {
                const getNumber = (str: any) => {
                    const match = str?.match(/\d+(\.\d+)?/);
                    return match ? parseFloat(match[0]) : 0;
                };
                return getNumber(a?.statusMessage) - getNumber(b?.statusMessage);
            },
            render: (text: any) => (
                <a
                    style={{
                        color: `${text?.split(" ")[0] === "Connected" ? "green" : "red"}`,
                    }}
                >
                    {text}
                </a>
            ),
        },
    ];

    const handleUrlSharing = () => {
        const params = new URLSearchParams(location?.search);
        const hash = location?.hash?.split('?')[1];
        const params2: any = queryString?.parse(hash, { arrayFormat: 'comma' });
        // console.log("params2", params2);


        const brandParam = params?.get('brands');
        let countryParam: any = []
        let storesParam: any = []
        const storeParam = params?.get('stores');
        // console.log("brand val = ", brandParam)
        if (params2?.brands?.length > 0) {
            setCheckedList(Array.isArray(params2.brands) ? params2.brands : [params2.brands]);
            onCheckBoxChange(Array.isArray(params2.brands) ? params2.brands : [params2.brands])
            if (params2?.brands) {
                countryParam = Array.isArray(params2.countries) ? params2.countries : [params2.countries]
            }
            if (params2?.stores) {
                storesParam = Array.isArray(params2.stores) ? params2.stores : [params2.stores]
            }
        } else {
            setCheckedList(['H&M']);
            onCheckBoxChange(['H&M'])
        }
    }

    useEffect(() => {
        const params = new URLSearchParams(location?.search);
        const brandParam = params?.get('brands');
        const hash = location?.hash?.split('?')[1];
        const params2 = queryString?.parse(hash, { arrayFormat: 'comma' });
        // console.log("params2..", params2);
        // console.log("vbrandParam = ", params2?.brands)
        const brands = params2?.brands
        const countries: any = params2?.countries
        const storeId = params2?.storeId

        let defaultCountry = GetLoggedInCountry();
        if (brandAndCountryStores?.length > 0 && !brands) {
            // console.log("Indian store countryStores", brandAndCountryStores)
            handleCountryOnchange([defaultCountry])
            // handleSetSelectedCountryCode([defaultCountry])
            // setTimeout(()=>{
            const countryStores = brandAndCountryStores?.filter((val: any) => val.country === defaultCountry && val.brand === "H&M")
            // console.log("Indian store countryStores", countryStores)

            let stores: any = countryStores[0]?.stores?.map((val: any) => {
                return {
                    key: val,
                    value: val,
                    checked: false
                }
            })

            setStoresOptions(stores)
            // console.log("Indian store countryStores", countryStores)
            handleStoreOnchange([stores[0]?.key])
            // },1000)
            // console.log("called use")

            const params = new URLSearchParams();
            params.set("brands", 'H&M');
            params.set("countries", defaultCountry);
            params.set("storeId", stores[0]?.key);
            navigate({ search: params.toString() }, { replace: true });
        }

        if (brandAndCountryStores?.length > 0 && brands) {
            handleCountryOnchange(typeof countries === "string" ? [countries] : countries)
            handleStoreOnchange(storeId ? (typeof storeId === "string" ? [storeId] : storeId) : [])
        }

    }, [brandAndCountryStores?.length])

    useEffect(() => {
        const params = new URLSearchParams();
        if (selectedCountry?.length > 0) {
            params.set(
                "brands",
                checkedList?.toString() || 'H&M'
            );
            params.set(
                "countries",
                selectedCountry?.toString() || defaultSelectedCountryId?.toString()
            );
            params.set(
                "storeId",
                selectedStore?.toString() || defaultStores?.toString()
            );
            navigate({ search: params.toString() }, { replace: true });
        }
    }, [
        selectedCountry,
        selectedStore,
        checkedList,
    ]);


    const handleFetchCountryStore = async () => {
        let token = await GetAccessToken();
        setStoresLoading(true); // Set loading to true before fetch
        try {
            const response = await fetch(
                `${environmentConfig.chatApiUrl}/StoreSmartPhone/GetNewBrandStoreCountryFilter`,
                {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {

                logAPIResponse([], `Failed to fetch get brand store country filter, /StoreSmartPhone/GetBrandStoreCountryFilter`)
                setStoresLoading(false);
                throw new Error("Network response was not ok");
            }
            const data: any = await response.json();
            // console.log("country stores = ", data)
            setBrandStoreResponse(data);
            setStoresLoading(false);
            handleUrlSharing()
        } catch (error: any) {

            setStoresLoading(false);
            logAPIResponse([], `Failed to fetch get brand store country filter, /StoreSmartPhone/GetBrandStoreCountryFilter. ${error}`)
        } finally {
            setStoresLoading(false); // Set loading to false after fetch
        }
    };

    useEffect(() => {
        handleFetchCountryStore();
    }, []);

    const handleSetCountryOnBrand = () => {
        let countries: any = [];
        let stores: any = [];
        const filteredRes: any = brandStoreResponse?.filter((brandResponse: any) =>
            checkedList?.includes(brandResponse?.brand)
        );
        handleBrandCountryAndBrandStores(); /* istanbul ignore */

        filteredRes?.forEach((brandDetails: any) => {
            brandDetails?.storeCountries?.forEach((val: any) => {
                const isObjectInArray = countries?.some(
                    (obj: any) => obj.key === val?.countryName
                );
                if (!isObjectInArray && val?.stores?.length > 0) {
                    countries.push({
                        key: val?.countryName,
                        value: val?.countryName,
                        checked: true,
                    });
                }
            });
        });
        setCountryOptions(countries);
    };

    useEffect(() => {
        handleSetCountryOnBrand(); /* istanbul ignore */
    }, [checkedList.length]);

    const fetchMusicInStoreReport = async (params: any) => {
        let token = await GetAccessToken();
        setLoading(true); // Set loading to true before fetch
        try {
            const response = await fetch(
                `${environmentConfig.chatApiUrl}/StoreReport/GetMusicDataStatusForWeb?storeId=${params.storeId}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            if (!response.ok) {
                logAPIResponse([], `Failed to fetch AEOS details, /StoreReport/GetAeosDetails`)
                setLoading(false);
                throw new Error("Network response was not ok");
            }
            const data: any = await response.json();
            setMusicInStoreData(data);
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            logAPIResponse([], `Failed to fetch AEOS details, /StoreReport/GetAeosDetails. ${error}`)
        } finally {
            setLoading(false); // Set loading to false after fetch
        }
    };

    useEffect(() => {
        if (selectedStore?.length > 0) {
            const param = {
                storeId: selectedStore?.join(","),
            }
            fetchMusicInStoreReport(param)
        }
    }, [selectedStore])

    return (
        <div>
            <Header pageTitle="Business Command Center" />
            {storesLoading ? <div className={styles.spinnerContainer}>
                <Spin size="large" className="custom-spin" data-testid="loading-spinner" />
            </div> : <div className={styles.mainDiv}>
                <div className={styles.routePageTitleWrapper}>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ paddingLeft: "0px" }}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </a>
                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                        onClick={() => navigate("/applicationhealth")}
                    >
                        Application Health
                    </a>
                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ textDecoration: "underline", cursor: "auto" }}
                    >
                        Music in Store
                    </a>
                </div>
                <div className={styles.musicinstoreHeader}>
                    <h2 className={styles.musicinstoreTitle}>Music in Store</h2>
                    <div className={styles.inputContainerDiv}>
                        <div className={styles.radioBtnsDiv}>
                            <Checkbox
                                // className="custom-checkbox"
                                // indeterminate={indeterminate}
                                onChange={onCheckAllChange}
                                checked={checkAll}
                            >
                                All Brands
                            </Checkbox>
                            <CheckboxGroup
                                options={plainOptions}
                                value={checkedList}
                                onChange={onCheckBoxChange}
                            />
                        </div>
                        <div className={styles.dropDownContainer}>
                            <div
                                className={`${styles.dropDownContainerDiv} ${styles.countryDropDown}`}
                            >
                                <DropDownList
                                    items={[
                                        {
                                            key: "All",
                                            value: `All`,
                                            checked: false,
                                        },
                                        ...countryOptions,
                                    ]}
                                    onSelect={handleCountryOnchange} /* istanbul ignore */
                                    unSelectAll={handleClearCountry}
                                    styleName="applicationHealthStyle"
                                    dropdownName="countryDropdown"
                                    displayName={
                                        displaySelectedCountry?.length === 0
                                            ? "Choose Country"
                                            : displaySelectedCountry
                                    }
                                    selectedDropDownItems={selectedCountry}
                                    isDisabled={countryOptions?.length === 0}
                                />
                            </div>
                            <div
                                className={`${styles.dropDownContainerDiv} ${styles.storeDropdown}`}
                            >
                                <DropDownList
                                    items={[
                                        // { value: "All", label: "Select All" },
                                        {
                                            key: "All",
                                            value: `All`,
                                            checked: false,
                                        },
                                        ...storeOptions,
                                    ]}
                                    onSelect={handleStoreOnchange}
                                    styleName="applicationHealthStyle"
                                    unSelectAll={handleUnSelectStore}
                                    displayName={
                                        displaySelectedStore?.length === 0
                                            ? "Choose Store"
                                            : displaySelectedStore
                                    }
                                    isDisabled={storeOptions?.length === 0}
                                    selectedDropDownItems={selectedStore}
                                    dropdownName="storeDropdown"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Divider
                    style={{
                        marginTop: "12px",
                        marginBottom: "15px",
                        height: "1px",
                        backgroundColor: "#cccccc",
                    }}
                ></Divider>
                <div className={styles.musicinstoreContent}>
                    <div className={styles.firstHalf}>
                        {loading ? (
                            <div className={styles.spinnerContainer}>
                                <Spin size="large" className="custom-spin" data-testid="loading-spinner" />
                            </div>
                        ) : <div className={styles.totalPlayersDiv}>
                            <div className={styles.totalplayersLabel}>Total Players</div>
                            <div className={styles.totalplayersValue} data-testid="totalPlayersValue">{musicInStoreData?.playersCount ? musicInStoreData?.playersCount : 0}</div>
                            <div>
                                <div>
                                    <div className={styles.offlinelabelvalue}>
                                        <div className={styles.offlineLabel}>OFFLINE</div>
                                        <div className={styles.value} data-testid="offlineCount">{musicInStoreData?.offlineCount ? musicInStoreData?.offlineCount : 0}</div>
                                    </div>
                                    <div className={styles.barContainer}>
                                        <div className={styles.OfflineBar}><div className={styles.OfflineBarVal} style={{ width: musicInStoreData?.offlinePercentage }}></div></div>
                                        <div className={styles.percentageValues} data-testid="offlinePercentage">{musicInStoreData?.offlinePercentage ? musicInStoreData?.offlinePercentage : '0%'}</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <div className={styles.unTrackedlabelvalue}>
                                        <div className={styles.unTrackedLabel}>UNABLE TO LOCATE IN NETWORK</div>
                                        <div className={styles.value} data-testid="offlineCount">{musicInStoreData?.notInNetworkCount ? musicInStoreData?.notInNetworkCount : 0}</div>
                                    </div>
                                    <div className={styles.barContainer}>
                                        <div className={styles.unTrackedBar}><div className={styles.untrackedBarVal} style={{ width: musicInStoreData?.notInNetworkPercentage }}></div></div>
                                        <div className={styles.unTrackedpercentageValues} data-testid="offlinePercentage">{musicInStoreData?.notInNetworkPercentage ? musicInStoreData?.notInNetworkPercentage : '0%'}</div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <div className={styles.onlinelabelvalue}>
                                        <div className={styles.onlineLabel}>ONLINE</div>
                                        <div className={styles.value} data-testid="onlineCount">{musicInStoreData?.onlinelineCount ? musicInStoreData?.onlinelineCount : 0}</div>
                                    </div>
                                    <div className={styles.barContainer}>
                                        <div className={styles.OnlineBar}><div className={styles.OnlineBarVal} style={{ width: musicInStoreData?.onlinePercentage }}></div></div>
                                        <div className={styles.onlinepercentageValues} data-testId="onlinePercentage">{musicInStoreData?.onlinePercentage ? musicInStoreData?.onlinePercentage : '0%'}</div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div style={{ marginTop: "30px" }}>
                            <div className={styles.playlistHeading}>Recent Play Lists</div>
                            {loading ? (
                                <div className={styles.spinnerContainer}>
                                    <Spin size="large" className="custom-spin" data-testid="loading-spinner" />
                                </div>
                            ) : <div className={styles.recentPlaylistDiv}>
                                <div className={styles.songMain}>
                                    <div className={styles.songImageDiv}></div>
                                    <div className={styles.songDetails}>
                                        <div className={styles.songName}>Mexican Duel Hip Hop - Loop</div>
                                        <div className={styles.songDesc}>Bell, Corporate, Documentary, Drama, Film dr....</div>
                                        <div className={styles.songTime}>1:28</div>
                                    </div>
                                </div>
                                <div className={styles.songMain}>
                                    <div className={styles.songImageDiv}></div>
                                    <div className={styles.songDetails}>
                                        <div className={styles.songName}>Mexican Duel Hip Hop - Loop</div>
                                        <div className={styles.songDesc}>Bell, Corporate, Documentary, Drama, Film dr....</div>
                                        <div className={styles.songTime}>1:28</div>
                                    </div>
                                </div><div className={styles.songMain}>
                                    <div className={styles.songImageDiv}></div>
                                    <div className={styles.songDetails}>
                                        <div className={styles.songName}>Mexican Duel Hip Hop - Loop</div>
                                        <div className={styles.songDesc}>Bell, Corporate, Documentary, Drama, Film dr....</div>
                                        <div className={styles.songTime}>1:28</div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                    <div className={styles.secondHalf}>
                        {loading ? (
                            <div className={styles.spinnerContainer}>
                                <Spin size="large" className="custom-spin" data-testid="loading-spinner" />
                            </div>
                        ) : (<CustomTable columns={columns} data={musicInStoreData?.musicDataStatuses} />)}
                    </div>
                </div>
            </div>}
        </div >
    )
}

export default MusicInStore