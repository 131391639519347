import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './StoreWithIssues.module.css';
import Header from "../../components/CommonComponents/Header";
import { Divider, Spin } from "antd";
import CustomTable from "../../components/CommonComponents/Table";
import ImpactedStoresMap from '../../components/mapComponent/ImpactedStoresMap';
import Item from 'antd/es/list/Item';

function StoreWithIssue() {
    const navigate = useNavigate();
    const mapRef = useRef<L.Map | null>(null);
    const [loading, setLoading] = useState(true);
    const [impactedCountryList, setImpactedCountryList] = useState([]);
    const [impactedStores, setImpactedStores] = useState([]);
    const [mapLocationDetails, setMapLocationDetails] = useState([])
    const [mapIcon, setMapIcon] = useState<'mapStreetIcon' | 'mapStoreRedCircleIcon' | 'mapStoreBrownCircleIcon'>("mapStoreRedCircleIcon");

    const impactedCountryListColumns: any = [
        {
            title: "Impacted Country List",
            dataIndex: "impactedCountryList",
            key: "impactedCountryList",
            // align:"center",
            width: 120,
            render: (text: any, item: any) => {
                return (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div>
                            <img style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                                objectFit: "cover",
                            }}
                                src={text?.length > 0 ? `https://cdn.jsdelivr.net/gh/lipis/flag-icons@7.3.2/flags/4x3/${item?.countryCode}.svg` : ""}
                            />
                        </div>
                        <Divider type="vertical" style={{ height: "20px", margin: "0 10px" }} />
                        <a>{text}</a>
                    </div>
                )
            },
        },
    ];
    const columns: any = [
        {
            title: "Priority",
            dataIndex: "priority",
            key: "priority",
            width: 280,
            render: (text: any) => <a>{text}</a>,
        },
        {
            title: "Store ID",
            dataIndex: "store_ID",
            key: "store_ID",
            width: 280,
            render: (text: any) => <a>{text ?
                <div style={{
                    color: 'black',
                    textDecoration: 'underline',
                    cursor: 'pointer'
                }} onClick={() => handleStoreClick(text)}>{text}</div> : "Not Available"}</a>,
        },
        {
            title: "Problem Description",
            dataIndex: "problem_Description",
            key: "problem_Description",
            // width: 100,
            render: (text: any) => <a>{text}</a>,
        }
    ];

    useEffect(() => {
        const impactedCountryListData: any = [
            {
                impactedCountryList: "India",
                countryCode: "in"
            },
            {
                impactedCountryList: "USA",
                countryCode: "us"
            },
            {
                impactedCountryList: "UK",
                countryCode: "gb"
            },
            {
                impactedCountryList: "Sweden",
                countryCode: "se"
            },
            {
                impactedCountryList: "Australia",
                countryCode: "au"
            },
            {
                impactedCountryList: "Austria",
                countryCode: "at"
            },
            {
                impactedCountryList: "Belgium",
                countryCode: "be"
            },
            {
                impactedCountryList: "Canada",
                countryCode: "ca"
            },
            {
                impactedCountryList: "China",
                countryCode: "cn"
            },

        ]

        const impactedStoresData: any = [
            {
                priority: "High",
                store_ID: "1234",
                problem_Description: "Store is not responding"
            },
            {
                priority: "Medium",
                store_ID: "5678",
                problem_Description: "Store is not responding"
            },
            {
                priority: "Low",
                store_ID: "9101",
                problem_Description: "Store is not responding"
            }
        ]
        setLoading(false);
        setImpactedStores(impactedStoresData);
        setImpactedCountryList(impactedCountryListData);
    }, []);

    const mapLocationData: { id: number; position: [number, number]; title: string; }[] = [
        { id: 1, position: [47.5162, 14.5501], title: 'AT0112' }, // Austria
        { id: 2, position: [20.5937, 78.9629], title: 'IN0001' }, // India
        { id: 3, position: [-25.2744, 133.7751], title: 'AU0034' }, // Australia
        { id: 4, position: [35.8617, 104.1954], title: 'CN0001' }, // China
        { id: 5, position: [61.5240, 105.3188], title: 'RU0001' }, // Russia
        { id: 6, position: [51.1657, 10.4515], title: 'DE0001' }, // Germany
        { id: 7, position: [36.2048, 138.2529], title: 'JP0001' }, // Japan
        { id: 8, position: [37.0902, -95.7129], title: 'US0001' }, // United States
        { id: 9, position: [55.3781, -3.4360], title: 'UK0001' }, // United Kingdom
        { id: 10, position: [46.6034, 1.8883], title: 'FR0001' }, // France
        { id: 11, position: [40.4637, -3.7492], title: 'ES0001' }
    ];

    const handleStoreClick = (storeId: any) => {
        const params = new URLSearchParams();
        console.log("storeId", storeId);
        params.set("storeId", storeId);

        // Navigate within the same tab
        navigate({ search: params.toString() }, { replace: true });

        // Open new tab with the correct URL and parameters
        // window.open(`/#/storewithissues?${params.toString()}`, "_blank");

        // Update map icon
        setMapIcon("mapStoreBrownCircleIcon");

        // Ensure map retains focus
        if (mapRef.current) {
            mapRef.current.getContainer().focus();
            mapRef.current.getContainer().style.outline = "1px solid #ccc"; /* Light blue border */
            mapRef.current.getContainer().style.outlineOffset = "2px"; /* Optional: Add space between the element and the outline */
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleBack = () => {
        setMapIcon("mapStoreRedCircleIcon");
        navigate("/storewithissues");
    }
    return (
        <div>
            <Header pageTitle="Business Command Center" />
            <div className={styles.mainDiv}>
                <div className={styles.routePageTitleWrapper}>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ paddingLeft: "0px" }}
                        onClick={() => navigate("/")}
                    >
                        Home
                    </a>
                    <a>&gt;</a>
                    <a
                        className={styles.breadCrumbsLink}
                        style={{ textDecoration: "underline", cursor: "auto" }}
                    >
                        Store with issues
                    </a>
                </div>
                <div>
                    <div style={{ display: "flex", marginBottom: 30 }}>
                        <div style={{ width: "25%" }}>
                            <div className={styles.headerContainer}>
                                <h2 className={styles.headerTitle}>Impacted Stores Overview</h2>
                            </div>
                            <div>
                                {loading ? (
                                    <div className={styles.spinnerContainer}>
                                        <Spin size="large" className="custom-spin" />
                                    </div>
                                ) : (
                                    <CustomTable columns={impactedCountryListColumns} data={impactedCountryList} isPagination={false} />
                                )}
                            </div>
                        </div>
                        <div style={{ width: "75%", marginLeft: "20px", zIndex: 1 }}>
                            <div> <ImpactedStoresMap mapLocationDetails={mapLocationData} mapIcon={mapIcon} handleBackClick={handleBack} mapRef={mapRef} /></div>
                        </div>
                    </div>
                    <div>
                        <div>
                            {loading ? (
                                <div className={styles.spinnerContainer}>
                                    <Spin size="large" className="custom-spin" />
                                </div>
                            ) : (
                                <CustomTable columns={columns} data={impactedStores} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default StoreWithIssue;