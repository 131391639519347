import React from "react";
import { Space, Table, Tag, ConfigProvider } from "antd";
import type { TableProps } from "antd";
import "../../assets/scss/_custom.scss";
import styles from "./Table.module.css";

interface DataType { }
interface CustomTableProps {
  columns: TableProps<DataType>["columns"];
  data?: DataType[];
  pageSize?: number;
  showSizeChanger?: boolean;
  isPagination?: boolean;
}

const CustomTable: React.FC<CustomTableProps> = ({
  columns,
  data,
  pageSize,
  showSizeChanger = true,
  isPagination = true,
}) => {
  const tableData = data?.map((item: any, index: any) => ({ ...item, key: index }));
  return (
    <Table
      columns={columns}
      dataSource={tableData}
      pagination={isPagination ? {
        pageSize,
        showSizeChanger,
        pageSizeOptions: ["5", "10", "20", "50"],
      } : isPagination}
      style={{
        border: "1px solid #CCCCCC",
        width: "100vw",
        overflowX: "scroll",
        fontSize: "12px",
      }}
      scroll={{ y: 'calc(100vh - 300px)' }}
      tableLayout="fixed"
    />
  );
};

export default CustomTable;
