import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../config/MsalConfig";
import { logUserLogin } from "../../components/CommonUtils/utils/logs_Utils";
import { GetUserName } from "../../services/AuthUser";
import { appInsights } from "../../services/TelemetryService";

interface UserDetails {
  userName: string;
  userEmail: string;
}

export const LoginFunctions = () => {
  const { instance } = useMsal();

  const handleLogin = async (loginType: "popup" | "redirect"): Promise<void> => {
    try {
      sessionStorage.removeItem("userLoggedIn")
      if (loginType === "popup") {
        await instance.loginPopup(loginRequest);
      } else if (loginType === "redirect") {
        await instance.loginRedirect(loginRequest);
      }
    } catch (error) {
      console.error('Login failed', error);
    }
  };

  return { handleLogin };
};
