import { useRef, useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { ArrowLeftOutlined } from '@ant-design/icons';

interface ImpactedStoresMapProps {
    mapLocationDetails: {
        id: number;
        position: [number, number];
        title: string;
    }[];
    mapIcon: 'mapStreetIcon' | 'mapStoreRedCircleIcon' | 'mapStoreBrownCircleIcon';
    handleBackClick: () => void;
    mapRef: React.RefObject<L.Map | null>;
}

const ImpactedStoresMap = ({ mapLocationDetails, mapIcon = "mapStreetIcon", handleBackClick, mapRef }: ImpactedStoresMapProps) => {
    // const mapRef = useRef<L.Map | null>(null);
    const controlRef = useRef<L.Control | null>(null);

    useEffect(() => {
        if (mapRef.current) {
            const bounds = L.latLngBounds(mapLocationDetails.map(location => location.position));
            mapRef.current.fitBounds(bounds);

            if (!controlRef.current) {
                const backControl = L.Control.extend({
                    options: { position: 'topleft' },
                    onAdd: function () {
                        const container = L.DomUtil.create('div', 'leaflet-bar leaflet-control leaflet-control-custom');
                        container.style.backgroundColor = 'white';
                        container.style.width = '33px';
                        container.style.height = '33px';
                        container.style.display = 'flex';
                        container.style.alignItems = 'center';
                        container.style.justifyContent = 'center';
                        container.style.cursor = 'pointer';
                        container.style.marginBottom = '10px';

                        container.innerHTML = `<span class="anticon"><svg viewBox="64 64 896 896" focusable="false" class="" data-icon="arrow-left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M869 474H218.2l292.3-292.3c6.2-6.2 6.2-16.4 0-22.6l-45.2-45.2c-6.2-6.2-16.4-6.2-22.6 0L98.1 488.5c-6.2 6.2-6.2 16.4 0 22.6l344.6 344.6c6.2 6.2 16.4 6.2 22.6 0l45.2-45.2c6.2-6.2 6.2-16.4 0-22.6L218.2 550H869c8.8 0 16-7.2 16-16v-64c0-8.8-7.2-16-16-16z"></path></svg></span>`;

                        container.onclick = handleBackClick;
                        return container;
                    }
                });

                controlRef.current = new backControl();
                mapRef.current.addControl(controlRef.current);
            }

            //Ensure zoom control is added and positioned
            // if (mapRef.current) {
            //     mapRef.current.zoomControl.setPosition('bottomleft');
            // } else {
            // L.control.zoom({ position: 'topleft' }).addTo(mapRef.current);
            ///  }
        }
    }, [mapLocationDetails, handleBackClick]);

    // useEffect(() => {
    //     if (mapRef.current) {
    //         const bounds = L.latLngBounds(mapLocationDetails.map(location => location.position));
    //         mapRef.current.fitBounds(bounds);
    //     }
    // }, [mapLocationDetails]);

    const createCustomIcon = (location: { id: number; position: [number, number]; title: string }) => {
        const iconHtml = {
            mapStreetIcon: `<div style="text-align: center;">
                <div style="display: inline-block; background-color: rgba(0, 0, 0, 0.63); color: white; border-radius: 5px; padding: 2px 5px;">${location.title}</div>
                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C8.13 2 5 5.13 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 5.13 15.87 2 12 2ZM12 11.5C10.62 11.5 9.5 10.38 9.5 9C9.5 7.62 10.62 6.5 12 6.5C13.38 6.5 14.5 7.62 14.5 9C14.5 10.38 13.38 11.5 12 11.5Z" fill="#FF0000"/>
                </svg>
            </div>`,
            mapStoreRedCircleIcon: `<div style="width: 20px; height: 20px; background-color: red; border-radius: 50%;"></div>`,
            mapStoreBrownCircleIcon: `<div style="text-align: center;">
                <div style="display: inline-block; background-color: rgba(0, 0, 0, 0.63); color: white; border-radius: 5px; padding: 2px 5px;">${location.title}</div>
                <div style="width: 20px; height: 20px; background-color: #f24b27; border: 2px solid #776262; border-radius: 50%;"></div>
            </div>`
        };

        return new L.DivIcon({
            html: iconHtml[mapIcon],
            className: 'custom-div-icon',
            iconSize: [32, 32],
            iconAnchor: [16, 32],
        });
    };

    return (
        <MapContainer center={[20, 0]} zoom={2} style={{ height: 455, width: '100%' }} ref={mapRef as React.RefObject<L.Map>}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {mapLocationDetails.map(location => (
                <Marker
                    key={location.id}
                    position={location.position}
                    icon={createCustomIcon(location)}
                >
                    <Popup>
                        <div>{location.title}</div>
                    </Popup>
                </Marker>
            ))}
        </MapContainer>
    );
};

export default ImpactedStoresMap;
