import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./StoreConfiguration.module.css";
import { notification, Modal, Divider, Spin, Popover, Button } from "antd";
import CustomTable from "../../components/CommonComponents/Table";
import Header from "../../components/CommonComponents/Header";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import "./StoreConfiguration.module.css";
import DropDownList from "../../components/CommonComponents/DropDownList";
import GenericButton from "../../components/CommonComponents/GenericButton";

const buttonStyle = {
  backgroundColor: "#222222",
  color: "white",
  fontSize: "16px",
  width: "85px",
  height: "32px",
  borderRadius: "2px",
};

const StoreConfiguration = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const [loading, setLoading] = useState(false);
  const [confirmBtnLoading, setConfirmBtnLoading] = useState(false);
  const [saveBtnLoading, setSaveBtnLoading] = useState(false);
  const [applicationFeaturesDetails, setApplicationFeaturesDetails] =
    useState<any>([]);
  const [configurationFilterData, setConfigurationFilterData] = useState<any>(
    []
  );
  const [showCurrentConfigurationDialog, setShowCurrentConfigurationDialog] =
    useState<any>(false);
  const [configurationStatus, setConfigurationStatus] = useState("");
  const [showEditMsgDialog, setShowEditMsgDialog] = useState<any>({});
  const [showDeleteConfigurationDialog, setShowDeleteConfigurationDialog] =
    useState<any>({});
  const [applicationList, setApplicationList] = useState<any>([]);
  const [iServiceList, setiServiceList] = useState<any>([]);
  const [brandList, setBrandList] = useState<any>([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [storesList, setStoresList] = useState<any>([]);
  const [selectedApplicationsList, setSelectedApplicationsList] = useState<any>(
    []
  );
  const [selectedIserviceList, setSelectedIserviceList] = useState<any>([]);
  const [selectedBrandsList, setSelectedBrandsList] = useState<any>([]);
  const [selectedCountryList, setSelectedCountryList] = useState<any>([]);
  const [selectedStoresList, setSelectedStoresList] = useState<any>([]);
  const [addSelectedApplicationsList, setAddSelectedApplicationsList] =
    useState<any>([]);
  const [addIserviceList, setAddIserviceList] = useState<any>([]);
  const [addSelectedIserviceList, setAddSelectedIserviceList] = useState<any>(
    []
  );
  const [addBrandList, setAddBrandList] = useState<any>([]);
  const [addSelectedBrandsList, setAddSelectedBrandsList] = useState<any>([]);
  const [addCountryList, setAddCountryList] = useState<any>([]);
  const [addSelectedCountryList, setAddSelectedCountryList] = useState<any>([]);
  const [addStoreList, setAddStoreList] = useState<any>([]);
  const [addSelectedStoresList, setAddSelectedStoresList] = useState<any>([]);

  const [editSelectedApplicationsList, setEditSelectedApplicationsList] =
    useState<any>([]);
  const [editIserviceList, setEditIserviceList] = useState<any>([]);
  const [editSelectedIserviceList, setEditSelectedIserviceList] = useState<any>(
    []
  );
  const [editBrandList, setEditBrandList] = useState<any>([]);
  const [editSelectedBrandsList, setEditSelectedBrandsList] = useState<any>([]);
  const [editCountryList, setEditCountryList] = useState<any>([]);
  const [editSelectedCountryList, setEditSelectedCountryList] = useState<any>(
    []
  );
  const [editStoreList, setEditStoreList] = useState<any>([]);
  const [editSelectedStoresList, setEditSelectedStoresList] = useState<any>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchCOnfigurationFilterList = async () => {
      let token = await GetAccessToken();
      setLoading(true);
      try {
        const response = await fetch(
          `${environmentConfig.chatApiUrl}/Admin/GetConfigurationFilter`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
              "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
            },
          }
        );
        if (!response.ok) {
          setLoading(false);
          throw new Error("Network response was not ok");
        }
        const data: any = await response.json();
        setConfigurationFilterData(data);

        setLoading(false);
      } catch (error: any) {
        console.error("Error while fetching configuration filter data:", error);
        setLoading(false);
      }
    };
    fetchCOnfigurationFilterList();
  }, []);

  useEffect(() => {
    if (Object.keys(configurationFilterData)?.length > 0) {
      const allApplicationList: any = [];
      configurationFilterData?.applications?.forEach((item: any) => {
        allApplicationList.push({
          ...item,
          key: item?.applicationName,
          value: item?.applicationName,
          features: item?.features,
          checked: false,
        });
      });
      setApplicationList(allApplicationList);
      const allBrandsList: any = [];
      configurationFilterData?.brandStoreCountries?.forEach((item: any) => {
        allBrandsList.push({
          key: item?.brand,
          value: item?.brand,
          checked: false,
        });
      });
      setBrandList(allBrandsList);
      setAddBrandList(allBrandsList);
      setEditBrandList(allBrandsList);
    }
  }, [configurationFilterData]);

  // API to Get applications features details data list
  /* v8 ignore start */
  const fetchApplicationFeatureDetails = async () => {
    let token = await GetAccessToken();
    let applicationIds: any = [];
    let featuresIds: any = [];
    applicationList?.forEach((item: any) => {
      if (selectedApplicationsList.includes(item?.applicationName)) {
        applicationIds.push(item.applicationId);
        item?.features?.forEach((featureItem: any) => {
          if (selectedIserviceList?.includes(featureItem?.featureName)) {
            featuresIds?.push(featureItem?.featureId);
          }
        });
      }
    });
    let selectedCountryIds: any = [];
    countryList?.forEach((item: any) => {
      if (selectedCountryList?.includes(item?.countryName)) {
        selectedCountryIds?.push(item?.countryId);
      }
    });
    let params: any = {};
    if (configurationStatus === "Add" || configurationStatus === "Edit") {
      params = {
        applicationIds: "",
        featureIds: "",
        corporateBrandIds: "",
        countryCodes: "",
        storeIds: "",
      };
    } else {
      params = {
        applicationIds: applicationIds?.join(",") || "",
        featureIds: featuresIds?.join(",") || "",
        corporateBrandIds:
          selectedBrandsList
            ?.filter((item: any) => item !== "All")
            ?.join(",") || "",
        countryCodes: selectedCountryIds?.join(",") || "",
        storeIds:
          selectedStoresList
            ?.filter((item: any) => item !== "All")
            ?.join(",") || "",
      };
    }

    try {
      setLoading(true);
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/Admin/GetApplicationFeatureList`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
          },

          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data: any = await response.json();
      let applicationFeaturesListData: any = [];
      data?.map((item: any) => {
        applicationFeaturesListData.push({
          ...item,
          configuredFeature: `${item?.applicationName} > ${item?.featureName}`,
        });
      });

      setApplicationFeaturesDetails(applicationFeaturesListData);
      setLoading(false);
    } catch (error: any) {
      console.error(
        "Error while fetching application features list data:",
        error
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (configurationStatus === "") fetchApplicationFeatureDetails();
  }, [
    selectedApplicationsList,
    selectedIserviceList,
    selectedBrandsList,
    selectedCountryList,
    selectedStoresList,
  ]);

  // API to insert application features details

  const addApplicationFeatureDetails = async (params: any) => {
    let token = await GetAccessToken();
    try {
      setSaveBtnLoading(true);
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/Admin/InsertApplicationFeatureDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
          },

          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setSaveBtnLoading(false);
        throw new Error("Network response was not ok");
      }
      notification.success({
        message: "Success",
        description: "Feature store mappings added successfully.",
        placement: "top"
      });
      setSaveBtnLoading(false);
      clearDropDownListData();
      clearFilterDropDownListData();
      fetchApplicationFeatureDetails();
      setShowEditMsgDialog(false);
      setShowCurrentConfigurationDialog(false);
    } catch (error: any) {
      notification.error({
        message: "Error",
        description: "There was an error while adding Feature store mappings.",
        placement: "top"
      });
      setSaveBtnLoading(false);
    }
  };

  // API to delete store configuration features details

  const deleteApplicationFeatureDetails: any = async (record: any) => {
    let token = await GetAccessToken();
    const params = record?.featureStoreMappingIds || [];
    try {
      setConfirmBtnLoading(true);
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/Admin/DeleteApplicationFeatureDetailsByMappingIds`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
          },
          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      notification.success({
        message: "Success",
        description: "Feature store mappings deleted successfully.",
        placement: "top"
      });
      setConfirmBtnLoading(false);
      setShowDeleteConfigurationDialog(false);
      clearFilterDropDownListData();
      fetchApplicationFeatureDetails();
    } catch (error: any) {
      notification.error({
        message: "Error",
        description:
          "There was an error while deleting Feature store mappings.",
        placement: "top"
      });
      setConfirmBtnLoading(false);
    }
  };

  // API to update store configuration feature details
  /* instanbul ignore function */
  const updateApplicationFeatureDetails = async (params: any) => {
    let token = await GetAccessToken();
    try {
      setSaveBtnLoading(true);
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/Admin/UpdateApplicationFeatureDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
          },

          body: JSON.stringify(params),
        }
      );
      if (!response.ok) {
        setSaveBtnLoading(false);
        throw new Error("Network response was not ok");
      }
      notification.success({
        message: "Success",
        description: "Feature store mappings updated successfully.",
        placement: "top"
      });
      setSaveBtnLoading(false);
      setShowEditMsgDialog(false);
      setShowCurrentConfigurationDialog(false);
      clearDropDownListData();
      clearFilterDropDownListData();
      fetchApplicationFeatureDetails();
    } catch (error: any) {
      setSaveBtnLoading(false);
      notification.error({
        message: "Error",
        description:
          "There was an error while updating Feature store mappings.",
        placement: "top"
      });
    }
  };

  const columns: any = [
    {
      title: "Configured Features",
      dataIndex: "configuredFeature",
      key: "configuredFeature",
      width: 200,
      align: "center",
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Configured Date",
      dataIndex: "configuredDate",
      key: "configuredDate",
      width: 200,
      align: "center",
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Edit/Delete",
      dataIndex: "editDelete",
      key: "editDelete",
      width: 240,
      align: "center",
      onHeaderCell: () => ({
        // style: { textAlign: 'center' as 'center' },
      }),
      render: (text: any, record: any, index: any) => (
        <div className={styles.serviceMessageColumn}>
          {
            <div
              style={{
                display: "flex",
                position: "relative",
                justifyContent: "center",
              }}
            >
              <Popover
                placement="leftTop"
                trigger="click"
                title={""}
                content={editStoreMsgContent(record)}
                open={showEditMsgDialog[index] || false}
                onOpenChange={() => {
                  setConfigurationStatus("Edit");
                  setShowEditMsgDialog((prev: any) => ({
                    ...prev,
                    [index]: !prev[index],
                  }));
                  handleEditUpdateFeature(record);
                }}
              >
                <div style={{ position: "relative" }}>
                  <span className={styles.editLogo} data-testid="editStoreMsg"></span>
                </div>
              </Popover>
              <Divider
                type="vertical"
                style={{
                  backgroundColor: "black",
                  position: "absolute",
                  right: "52.5%",
                  height: "34px",
                }}
              ></Divider>
              <div style={{ position: "relative" }}>
                <Popover
                  placement="leftTop"
                  trigger="click"
                  title={""}
                  content={deleteStoreConfigurationMsgContent(
                    record,
                    record?.configuredFeature
                  )}
                  open={showDeleteConfigurationDialog[index] || false}
                  onOpenChange={() => {
                    setShowDeleteConfigurationDialog((prev: any) => ({
                      ...prev,
                      [index]: !prev[index],
                    }));
                    setConfigurationStatus("");
                  }}
                >
                  <div style={{ position: "relative" }}>
                    <span className={styles.deleteLogo} data-testid="deleteStoreMsg"></span>
                  </div>
                </Popover>
              </div>
            </div>
          }
        </div>
      ),
    },
  ];

  const displaySelectedItemTitle = (selectedItem: any, dropdownTitle: any) => {
    return selectedItem?.length > 0
      ? [...new Set(selectedItem)]?.join(",")
      : dropdownTitle;
  };

  const displayDropDownListItem = (itemList: any) => {
    return itemList?.length > 0
      ? [
        {
          key: `All`,
          value: `All`,
          checked: false,
        },
        ...itemList,
      ]
      : [];
  };

  const handleEditUpdateFeature = (record: any) => {
    if ([record?.applicationName]?.length > 0) {
      let selectedApplicationsFeatures: any = [];
      applicationList?.filter((item: any) => {
        if ([record?.applicationName]?.includes(item?.value)) {
          item?.features?.forEach((feature: any) => {
            selectedApplicationsFeatures.push({
              key: feature?.featureId,
              value: feature?.featureName,
            });
          });
        }
      });
      const allSelectedBrandsCountriesList: any = [];
      const selectedCountry: any = [];
      configurationFilterData?.brandStoreCountries?.forEach((item: any) => {
        if (record?.brands?.includes(item?.brand)) {
          item?.storeCountries?.forEach((countriesItem: any) => {
            allSelectedBrandsCountriesList.push({
              ...countriesItem,
              key: countriesItem?.countryName,
              value: countriesItem?.countryName,
              checked: false,
            });
            if (record?.countryCodes?.includes(countriesItem?.countryId)) {
              selectedCountry.push(countriesItem?.countryName);
            }
          });
        }
      });
      let editCountryStoresList: any = [];
      configurationFilterData?.brandStoreCountries?.forEach((item: any) => {
        item?.storeCountries?.forEach((countriesItem: any) => {
          if (selectedCountry?.includes(countriesItem?.countryName)) {
            countriesItem?.stores?.forEach((storeItem: any) => {
              editCountryStoresList?.push({
                ...storeItem,
                key: storeItem?.storeId,
                value: storeItem?.storeId,
                checked: false,
              });
            });
          }
        });
      });
      setEditIserviceList(selectedApplicationsFeatures);
      setEditSelectedIserviceList([record?.featureName]);
      // setEditBrandList(record?.brands);
      setEditSelectedBrandsList(record?.brands);
      setEditCountryList(allSelectedBrandsCountriesList);
      setEditSelectedCountryList(selectedCountry);
      setEditStoreList(editCountryStoresList);
      setEditSelectedStoresList(record?.storeIds);
    } else {
      setAddSelectedApplicationsList([]);
      setEditSelectedApplicationsList([]);
      setSelectedApplicationsList([]);
      setAddIserviceList([]);
      setEditIserviceList([]);
      setiServiceList([]);
    }
  };

  const clearDropDownListData = () => {
    if (configurationStatus === "Add") {
      setAddSelectedApplicationsList([]);
      setAddSelectedIserviceList([]);
      setAddIserviceList([]);
      setAddSelectedBrandsList([]);
      setAddSelectedCountryList([]);
      setAddCountryList([]);
      setAddSelectedStoresList([]);
      setAddStoreList([]);
    } else if (configurationStatus === "Edit") {
      setEditSelectedApplicationsList([]);
      setEditSelectedIserviceList([]);
      setEditIserviceList([]);
      setEditSelectedBrandsList([]);
      setEditSelectedCountryList([]);
      setEditCountryList([]);
      setEditSelectedStoresList([]);
      setEditStoreList([]);
    } else if (configurationStatus === "") {
      setSelectedApplicationsList([]);
      setiServiceList([]);
      setSelectedIserviceList([]);
      setSelectedBrandsList([]);
      setSelectedCountryList([]);
      setCountryList([]);
      setSelectedStoresList([]);
      setStoresList([]);
    }
  };

  const editStoreMsgContent = (record?: any) => {
    return (
      <div className={styles.popoverContainer}>
        <div style={{ display: "flex", marginTop: 20 }}>
          <div className={styles.msgHeading}>
            {configurationStatus === "Add"
              ? "Add  Current Configuration"
              : "Modify Current Configuration"}
          </div>
          <div
            className={styles.closeIcon}
            onClick={() => {
              setShowEditMsgDialog(false);
              setShowCurrentConfigurationDialog(false);
              setConfigurationStatus("");
              clearDropDownListData();
            }}
          ></div>
        </div>
        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>
        <div style={{ display: "flex", margin: "30px 0px" }}>
          <div className={`${styles.dropDownContainerDiv} `}>
            <DropDownList
              items={
                applicationList?.length > 0
                  ? [
                    {
                      key: `All`,
                      value: `All`,
                      checked: false,
                    },
                    ...applicationList,
                  ]
                  : []
              }
              dropDownWidth={configurationStatus === "Add" ? 250 : 180}
              onSelect={handleApplicationsDropDownChange}
              handleClearAll={handleApplicationClearAll}
              unSelectAll={() => {
                setSelectedApplicationsList([]);
              }}
              selectedDropDownItems={
                configurationStatus === "Add"
                  ? addSelectedApplicationsList
                  : [...editSelectedApplicationsList, record?.applicationName]
              }
              styleName={"storeConfigurationStyle"}
              isTooltipEnabled={true}
              displayName={
                configurationStatus === "Add"
                  ? displaySelectedItemTitle(
                    addSelectedApplicationsList,
                    "Applications"
                  )
                  : displaySelectedItemTitle(
                    [
                      ...editSelectedApplicationsList,
                      record?.applicationName,
                    ],
                    "Applications"
                  )
              }
              isDisabled={
                applicationList?.length === 0 || configurationStatus === "Edit"
              }
              dropdownName="countryDropdown"
            />
          </div>
          <div className={`${styles.dropDownContainerDiv} `}>
            <DropDownList
              items={
                configurationStatus === "Add"
                  ? displayDropDownListItem(addIserviceList)
                  : displayDropDownListItem(editIserviceList)
              }
              dropDownWidth={configurationStatus === "Add" ? 250 : 160}
              onSelect={handleIserviceDropDownChange}
              unSelectAll={() => {
                setSelectedIserviceList([]);
              }}
              selectedDropDownItems={
                configurationStatus === "Add"
                  ? addSelectedIserviceList
                  : editSelectedIserviceList
              }
              styleName={"storeConfigurationStyle"}
              isTooltipEnabled={true}
              displayName={
                configurationStatus === "Add"
                  ? displaySelectedItemTitle(
                    addSelectedIserviceList,
                    "iService Features"
                  )
                  : displaySelectedItemTitle(
                    editSelectedIserviceList,
                    "iService Features"
                  )
              }
              isDisabled={
                addIserviceList?.length === 0 || configurationStatus === "Edit"
              }
              dropdownName="countryDropdown"
            />
          </div>
          <div className={`${styles.dropDownContainerDiv}`}>
            <DropDownList
              items={
                configurationStatus === "Add"
                  ? displayDropDownListItem(addBrandList)
                  : displayDropDownListItem(editBrandList)
              }
              dropDownWidth={150}
              onSelect={handleBrandsDropDownChange}
              handleClearAll={handleBrandClearAll}
              unSelectAll={() => {
                setSelectedBrandsList([]);
              }}
              selectedDropDownItems={
                configurationStatus === "Add"
                  ? addSelectedBrandsList
                  : editSelectedBrandsList
              }
              styleName={"storeConfigurationStyle"}
              isTooltipEnabled={true}
              displayName={
                configurationStatus === "Add"
                  ? displaySelectedItemTitle(addSelectedBrandsList, "Brands")
                  : displaySelectedItemTitle(editSelectedBrandsList, "Brands")
              }
              isDisabled={brandList?.length === 0}
              dropdownName="countryDropdown"
            />
          </div>
          <div className={`${styles.dropDownContainerDiv} `}>
            <DropDownList
              items={
                configurationStatus === "Add"
                  ? displayDropDownListItem(addCountryList)
                  : displayDropDownListItem(editCountryList)
              }
              onSelect={handleCountryDropDownChange}
              handleClearAll={handleCountryListClearAll}
              unSelectAll={() => {
                setSelectedCountryList([]);
              }}
              selectedDropDownItems={
                configurationStatus === "Add"
                  ? addSelectedCountryList
                  : editSelectedCountryList
              }
              styleName={"storeConfigurationStyle"}
              displayName={
                configurationStatus === "Add"
                  ? displaySelectedItemTitle(
                    addSelectedCountryList,
                    "Choose Country"
                  )
                  : displaySelectedItemTitle(
                    editSelectedCountryList,
                    "Choose Country"
                  )
              }
              isDisabled={
                configurationStatus === "Add"
                  ? addCountryList?.length === 0
                  : editCountryList?.length === 0
              }
              dropdownName="countryDropdown"
            />
          </div>
          <div className={`${styles.dropDownContainerDiv} `}>
            <DropDownList
              items={
                configurationStatus === "Add"
                  ? displayDropDownListItem(addStoreList)
                  : displayDropDownListItem(editStoreList)
              }
              onSelect={handleStoresDropDownChange}
              handleClearAll={handleStoresListClearAll}
              unSelectAll={() => {
                setSelectedStoresList([]);
              }}
              selectedDropDownItems={
                configurationStatus === "Add"
                  ? addSelectedStoresList
                  : editSelectedStoresList
              }
              styleName={"storeConfigurationStyle"}
              displayName={
                configurationStatus === "Add"
                  ? displaySelectedItemTitle(addSelectedStoresList, "Store ID")
                  : displaySelectedItemTitle(editSelectedStoresList, "Store ID")
              }
              isDisabled={
                configurationStatus === "Add"
                  ? addStoreList?.length === 0
                  : editStoreList?.length === 0
              }
              dropdownName="countryDropdown"
            />
          </div>
        </div>
        <div className={styles.btnContainer}>
          {saveBtnLoading ? (
            <Spin size="default" className="custom-spin">
              <Button
                data-testid="saveChangesBtn"
                className={styles.AddBtn}
                onClick={() => handleSave(record)}
              >
                Save Changes
              </Button>
            </Spin>
          ) : (
            <Button
              data-testid="saveChangesBtn"
              className={styles.AddBtn}
              onClick={() => handleSave(record)}
            >
              Save Changes
            </Button>
          )}
          <Button
            className={styles.CancelBtn}
            onClick={() => {
              setShowEditMsgDialog(false);
              setShowCurrentConfigurationDialog(false);
              clearDropDownListData();
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const handleSave = (record: any) => {
    let params = {};
    if (configurationStatus === "Add") {
      let filteredAddApplicationFeaturesList: any = [];
      applicationList?.forEach((item: any) => {
        if (addSelectedApplicationsList?.includes(item?.applicationName)) {
          item?.features?.forEach((featureItem: any) => {
            if (addSelectedIserviceList?.includes(featureItem?.featureName)) {
              filteredAddApplicationFeaturesList?.push({
                ...featureItem,
              });
            }
          });
        }
      });
      params = {
        featureIds:
          filteredAddApplicationFeaturesList
            ?.map((item: any) => item?.featureId)
            ?.join(",") || "",
        storeIds:
          addSelectedStoresList
            ?.filter((item: any) => item !== "All")
            ?.join(",") || "",
      };
      if (
        filteredAddApplicationFeaturesList?.map((item: any) => item?.featureId)
          ?.length === 0
      ) {
        notification.error({
          message: "Error",
          description:
            "Please select the iService feature from the dropdown list",
          placement: "top"
        });
        return false;
      } else if (addSelectedStoresList?.length === 0) {
        notification.error({
          message: "Error",
          description: "Please select the store ID from the dropdown list",
          placement: "top"
        });
        return false;
      }
      addApplicationFeatureDetails(params);
    } else if (configurationStatus === "Edit") {
      let filteredEditApplicationFeaturesList: any = [];
      applicationList?.forEach((item: any) => {
        if (editSelectedApplicationsList?.includes(item?.applicationName)) {
          item?.features?.forEach((featureItem: any) => {
            if (editSelectedIserviceList?.includes(featureItem?.featureName)) {
              filteredEditApplicationFeaturesList?.push({
                ...featureItem,
              });
            }
          });
        }
      });
      params = {
        featureIds: `${record?.featureId}` || "",
        storeIds:
          editSelectedStoresList
            ?.filter((item: any) => item !== "All")
            ?.join(",") || "",
      };
      updateApplicationFeatureDetails(params);
    }
  };

  const deleteStoreConfigurationMsgContent = (record: any, message: any) => {
    return (
      <div className={styles.popoverContainer}>
        <div style={{ display: "flex", marginTop: 20 }}>
          <div className={styles.msgHeading}>Confirm Delete</div>
          <div
            className={styles.closeIcon}
            onClick={() => {
              setShowDeleteConfigurationDialog(false);
            }}
          ></div>
        </div>
        <div style={{ margin: "10px 40px" }}>
          <div
            style={{
              fontFamily: "HM Sans",
              fontSize: "14px",
              fontWeight: 200,
              lineHeight: "32px",
              textAlign: "center",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            Would you like to delete the configuration feature for
          </div>
          <div
            style={{
              fontFamily: "HM Sans",
              fontSize: "15px",
              fontWeight: 400,
              lineHeight: "32px",
              textAlign: "center",
              textUnderlinePosition: "from-font",
              textDecorationSkipInk: "none",
            }}
          >
            {`"${message}" ?`}
          </div>
        </div>
        <div className={styles.btnContainer}>
          {confirmBtnLoading ? (
            <Spin size="default" className="custom-spin">
              <Button className={styles.AddBtn}>Confirm</Button>
            </Spin>
          ) : (
            <Button
              className={styles.AddBtn}
              onClick={() => deleteApplicationFeatureDetails(record)}
            >
              Confirm
            </Button>
          )}
          <Button
            className={styles.CancelBtn}
            onClick={() => {
              setShowDeleteConfigurationDialog(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  const handleApplicationsDropDownChange = (updatedItems: any[]) => {
    let selectedApplicationsFeatures: any = [];
    applicationList?.filter((item: any) => {
      if (updatedItems?.includes(item?.value)) {
        item?.features?.forEach((feature: any) => {
          selectedApplicationsFeatures.push({
            key: feature?.featureName,
            value: feature?.featureName,
          });
        });
      }
    });
    if (updatedItems?.length > 0) {
      if (configurationStatus === "Add") {
        setAddSelectedApplicationsList([...updatedItems]);
        setAddIserviceList(selectedApplicationsFeatures);
        if (selectedApplicationsFeatures?.length === 0) {
          setAddSelectedIserviceList([]);
        }
      } else if (configurationStatus === "Edit") {
        setEditSelectedApplicationsList([...updatedItems]);
        setEditIserviceList(selectedApplicationsFeatures);
        if (selectedApplicationsFeatures?.length === 0) {
          setEditSelectedIserviceList([]);
        }
      } else {
        setSelectedApplicationsList([...updatedItems]);
        setiServiceList(selectedApplicationsFeatures);
        if (selectedApplicationsFeatures?.length === 0) {
          setSelectedIserviceList([]);
        }
      }
    } else {
      if (
        configurationStatus === "Add" &&
        selectedApplicationsFeatures?.length === 0
      ) {
        setAddIserviceList([]);
        setAddSelectedIserviceList([]);
        setAddSelectedApplicationsList([]);
      } else if (
        configurationStatus === "Edit" &&
        selectedApplicationsFeatures?.length === 0
      ) {
        setEditIserviceList([]);
        setEditSelectedApplicationsList([]);
        setSelectedApplicationsList([]);
        setEditSelectedIserviceList([]);
      } else {
        setiServiceList([]);
        setSelectedIserviceList([]);
      }
    }
  };

  const handleIserviceDropDownChange = (updatedItems: any[]) => {
    if (updatedItems?.length > 0) {
      if (configurationStatus === "Add") {
        setAddSelectedIserviceList([...updatedItems]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedIserviceList([...updatedItems]);
      } else {
        setSelectedIserviceList([...updatedItems]);
      }
    } else {
      setAddSelectedIserviceList([]);
      setEditSelectedIserviceList([]);
      setSelectedIserviceList([]);
    }
  };

  const updateCountryStoresDropDownList = (
    selectedBrands: any,
    selectedCountries: any,
    selectedStores: any
  ) => {
    if (selectedBrands?.length > 0) {
      const allSelectedBrandsCountriesList: any = [];
      const brandsSelectedCountriesListArray: any = [];
      const selectedCountriesList: any = [];
      const selectedCountryStores: any = [];
      configurationFilterData?.brandStoreCountries?.forEach((item: any) => {
        if (selectedBrands?.includes(item?.brand)) {
          item?.storeCountries?.forEach((countriesItem: any) => {
            allSelectedBrandsCountriesList.push({
              ...countriesItem,
              key: countriesItem?.countryName,
              value: countriesItem?.countryName,
              checked: false,
            });

            if (selectedCountries?.includes(countriesItem?.countryName)) {
              selectedCountriesList.push(countriesItem?.countryName);
              countriesItem?.stores?.forEach((storeItem: any) => {
                selectedCountryStores?.push({
                  ...storeItem,
                  key: storeItem?.storeId,
                  value: storeItem?.storeId,
                  checked: false,
                });
              });
            }
          });
        }
      });

      const selectedStoresList: any = [];
      selectedCountryStores?.forEach((item: any) => {
        if (selectedStores?.includes(item?.storeId)) {
          selectedStoresList.push(item?.storeId);
        }
      });
      console.log(
        "configurationStatus",
        configurationStatus,
        selectedBrands,
        selectedCountries,
        selectedCountriesList,
        allSelectedBrandsCountriesList
      );
      if (configurationStatus === "Add") {
        setAddSelectedCountryList([...new Set(selectedCountriesList)]);
        setAddCountryList(allSelectedBrandsCountriesList);
        setAddSelectedStoresList(selectedStoresList);
        setAddStoreList(selectedCountryStores);
      } else if (configurationStatus === "Edit") {
        setEditSelectedCountryList([...new Set(selectedCountriesList)]);
        setEditCountryList(allSelectedBrandsCountriesList);
        setEditSelectedStoresList(selectedStoresList);
        setEditStoreList(selectedCountryStores);
      } else if (configurationStatus === "") {
        setSelectedCountryList([...new Set(selectedCountriesList)]);
        setCountryList(allSelectedBrandsCountriesList);
        setSelectedStoresList(selectedStoresList);
        setStoresList(selectedCountryStores);
      }
    }
  };

  const clearOnBrandChangeSelection = () => {
    if (configurationStatus === "Add") {
      setAddSelectedCountryList([]);
      setAddStoreList([]);
      setAddSelectedStoresList([]);
    } else if (configurationStatus === "Edit") {
      setEditSelectedCountryList([]);
      setEditStoreList([]);
      setEditSelectedStoresList([]);
    } else {
      setSelectedCountryList([]);
      setStoresList([]);
      setSelectedStoresList([]);
    }
  };

  const handleBrandsDropDownChange = (updatedItems: any[]) => {
    if (updatedItems?.length > 0) {
      if (configurationStatus === "Add") {
        // clearOnBrandChangeSelection();
        setAddSelectedBrandsList([...updatedItems]);
        updateCountryStoresDropDownList(
          [...updatedItems],
          addSelectedCountryList,
          addSelectedStoresList
        );
      } else if (configurationStatus === "Edit") {
        // clearOnBrandChangeSelection();
        setEditSelectedBrandsList([...updatedItems]);
        updateCountryStoresDropDownList(
          [...updatedItems],
          editSelectedCountryList,
          editSelectedStoresList
        );
      } else {
        clearOnBrandChangeSelection();
        setSelectedBrandsList([...updatedItems]);
        updateCountryStoresDropDownList(
          [...updatedItems],
          selectedCountryList,
          selectedStoresList
        );
      }
    } else {
      setAddSelectedBrandsList([]);
      setEditSelectedBrandsList([]);
      setSelectedBrandsList([]);
      setAddCountryList([]);
      setEditCountryList([]);
      setCountryList([]);
      clearOnBrandChangeSelection();
    }
  };

  const handleCountryDropDownChange = (updatedItems: any[]) => {
    let selectedStores: any = [];
    let selectedCountryStoresList: any = [];
    let countryListData: any = [];
    if (configurationStatus === "Add") {
      countryListData = addCountryList;
      selectedStores = addSelectedStoresList;
    } else if (configurationStatus === "Edit") {
      countryListData = editCountryList;
      selectedStores = editSelectedStoresList;
    } else if (configurationStatus === "") {
      countryListData = countryList;
      selectedStores = selectedStoresList;
    }
    const displayStores: any = [];
    countryListData?.forEach((item: any) => {
      if (updatedItems?.includes(item?.countryName)) {
        item?.stores?.forEach((storeItem: any) => {
          if (selectedStores?.includes(storeItem?.storeId)) {
            displayStores?.push(storeItem?.storeId);
          }
          selectedCountryStoresList?.push({
            ...storeItem,
            key: storeItem?.storeId,
            value: storeItem?.storeId,
            checked: false,
          });
        });
      }
    });
    console.log("countryListData", countryListData, selectedCountryStoresList);

    if (updatedItems?.length > 0) {
      if (configurationStatus === "Add") {
        setAddSelectedCountryList([...updatedItems]);
        setAddSelectedStoresList(displayStores);
        setAddStoreList(selectedCountryStoresList);
      } else if (configurationStatus === "Edit") {
        setEditSelectedCountryList([...updatedItems]);
        setEditSelectedStoresList(displayStores);
        setEditStoreList(selectedCountryStoresList);
      } else {
        setSelectedCountryList([...updatedItems]);
        setSelectedStoresList(displayStores);
        setStoresList(selectedCountryStoresList);
      }
    } else {
      if (configurationStatus === "Add") {
        setAddSelectedCountryList([]);
        setAddStoreList([]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedCountryList([]);
        setEditStoreList([]);
        setEditSelectedStoresList([]);
      } else {
        setSelectedCountryList([]);
        setStoresList([]);
      }
    }
  };

  const handleStoresDropDownChange = (updatedItems: any[]) => {
    if (updatedItems?.length > 0) {
      if (configurationStatus === "Add") {
        setAddSelectedStoresList([...updatedItems]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedStoresList([...updatedItems]);
      } else {
        setSelectedStoresList([...updatedItems]);
      }
    } else {
      setAddSelectedStoresList([]);
      setEditSelectedStoresList([]);
      setSelectedStoresList([]);
    }
  };

  const handleBtnClick = () => { };

  const handleApplicationClearAll = (isClear: boolean) => {
    if (isClear) {
      if (configurationStatus === "Add") {
        setAddSelectedApplicationsList([]);
        setAddIserviceList([]);
        setAddSelectedIserviceList([]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedApplicationsList([]);
        setEditIserviceList([]);
        setEditSelectedIserviceList([]);
      } else if (configurationStatus === "") {
        setSelectedApplicationsList([]);
        setiServiceList([]);
        setSelectedIserviceList([]);
      }
    }
  };

  const handleBrandClearAll = (isClear: boolean) => {
    if (isClear) {
      if (configurationStatus === "Add") {
        setAddSelectedBrandsList([]);
        setAddCountryList([]);
        setAddSelectedCountryList([]);
        setAddStoreList([]);
        setAddSelectedStoresList([]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedBrandsList([]);
        setEditCountryList([]);
        setEditSelectedCountryList([]);
        setEditStoreList([]);
        setEditSelectedStoresList([]);
      } else if (configurationStatus === "") {
        setSelectedBrandsList([]);
        setCountryList([]);
        setSelectedCountryList([]);
        setSelectedStoresList([]);
        setStoresList([]);
      }
    }
  };

  const handleCountryListClearAll = (isClear: boolean) => {
    if (isClear) {
      if (configurationStatus === "Add") {
        setAddSelectedCountryList([]);
        setAddStoreList([]);
        setAddSelectedStoresList([]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedCountryList([]);
        setEditStoreList([]);
        setEditSelectedStoresList([]);
      } else if (configurationStatus === "") {
        setSelectedCountryList([]);
        setStoresList([]);
        setSelectedStoresList([]);
      }
    }
  };

  const handleStoresListClearAll = (isClear: boolean) => {
    if (isClear) {
      if (configurationStatus === "Add") {
        setAddSelectedStoresList([]);
      } else if (configurationStatus === "Edit") {
        setEditSelectedStoresList([]);
      } else if (configurationStatus === "") {
        setSelectedStoresList([]);
      }
    }
  };

  const clearFilterDropDownListData = () => {
    setSelectedApplicationsList([]);
    setSelectedIserviceList([]);
    setSelectedBrandsList([]);
    setSelectedCountryList([]);
    setSelectedStoresList([]);
  };
  /* v8 ignore stop */
  return (
    <div>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a
            className={styles.breadCrumbsLink}
            style={{ paddingLeft: "0px" }}
            onClick={() => navigate("/")}
          >
            Home
          </a>
          <a>&gt;</a>
          <a
            className={styles.breadCrumbsLink}
          // onClick={() => navigate("/applicationhealth")}
          >
            Configuration
          </a>
        </div>

        <div
          className={styles.headerDiv}
        // style={{ display: window.innerWidth < 600 ? "block" : "flex" }}
        >
          <div className={styles.heading}>
            <h2 className={styles.storeConfigureTitle}>Store Configuration</h2>
          </div>
          <div className={styles.filterDiv}>
            <div className={styles.filterFirstHalf}>
              <div className={`${styles.dropDownContainerDiv} `} data-testid="filterApplicationDropDown">
                <DropDownList
                  items={
                    applicationList?.length > 0
                      ? [
                        {
                          key: `All`,
                          value: `All`,
                          checked: false,
                        },
                        ...applicationList,
                      ]
                      : []
                  }
                  dropDownWidth={250}
                  onSelect={handleApplicationsDropDownChange}
                  handleClearAll={handleApplicationClearAll}
                  unSelectAll={() => {
                    setSelectedApplicationsList([]);
                  }}
                  selectedDropDownItems={selectedApplicationsList}
                  styleName={"storeConfigurationStyle"}
                  displayName={
                    selectedApplicationsList?.length === 0
                      ? "Applications"
                      : selectedApplicationsList?.join(",")
                  }
                  isDisabled={applicationList?.length === 0}
                  dropdownName="countryDropdown"
                />
              </div>
              <div className={`${styles.dropDownContainerDiv} `} data-testid="filterIserviceDropDown">
                <DropDownList
                  items={
                    iServiceList?.length > 0
                      ? [
                        {
                          key: `All`,
                          value: `All`,
                          checked: false,
                        },
                        ...iServiceList,
                      ]
                      : []
                  }
                  dropDownWidth={250}
                  onSelect={handleIserviceDropDownChange}
                  unSelectAll={() => {
                    setSelectedIserviceList([]);
                  }}
                  selectedDropDownItems={selectedIserviceList}
                  styleName={"storeConfigurationStyle"}
                  displayName={
                    selectedIserviceList?.length === 0
                      ? "iService Features"
                      : selectedIserviceList?.join(",")
                  }
                  isDisabled={iServiceList?.length === 0}
                  dropdownName="countryDropdown"
                />
              </div>
            </div>
            <div className={styles.filterSecondHalf}>
              <div className={`${styles.dropDownContainerDiv}`} data-testid="filterBrandDropDown">
                <DropDownList
                  items={
                    brandList?.length > 0
                      ? [
                        {
                          key: `All`,
                          value: `All`,
                          checked: false,
                        },
                        ...brandList,
                      ]
                      : []
                  }
                  dropDownWidth={150}
                  onSelect={handleBrandsDropDownChange}
                  handleClearAll={handleBrandClearAll}
                  unSelectAll={() => {
                    setSelectedBrandsList([]);
                  }}
                  selectedDropDownItems={selectedBrandsList}
                  styleName={"storeConfigurationStyle"}
                  displayName={
                    selectedBrandsList?.length === 0
                      ? "Brands"
                      : selectedBrandsList?.join(",")
                  }
                  isDisabled={brandList?.length === 0}
                  dropdownName="countryDropdown"
                />
              </div>
              <div className={`${styles.dropDownContainerDiv} `} data-testid="filterCountryDropDown">
                <DropDownList
                  items={
                    countryList?.length > 0
                      ? [
                        {
                          key: `All`,
                          value: `All`,
                          checked: false,
                        },
                        ...countryList,
                      ]
                      : []
                  }
                  onSelect={handleCountryDropDownChange}
                  handleClearAll={handleCountryListClearAll}
                  unSelectAll={() => {
                    setSelectedCountryList([]);
                  }}
                  selectedDropDownItems={selectedCountryList}
                  styleName={"storeConfigurationStyle"}
                  displayName={
                    selectedCountryList?.length === 0
                      ? "Choose Country"
                      : selectedCountryList?.join(",")
                  }
                  isDisabled={countryList?.length === 0}
                  dropdownName="countryDropdown"
                />
              </div>
              <div className={`${styles.dropDownContainerDiv} `} data-testid="filterStoreDropDown">
                <DropDownList
                  items={
                    storesList?.length > 0
                      ? [
                        {
                          key: `All`,
                          value: `All`,
                          checked: false,
                        },
                        ...storesList,
                      ]
                      : []
                  }
                  onSelect={handleStoresDropDownChange}
                  handleClearAll={handleStoresListClearAll}
                  unSelectAll={() => {
                    setSelectedStoresList([]);
                  }}
                  selectedDropDownItems={selectedStoresList}
                  styleName={"storeConfigurationStyle"}
                  displayName={
                    selectedStoresList?.length === 0
                      ? "Store ID"
                      : selectedStoresList?.join(",")
                  }
                  isDisabled={storesList?.length === 0}
                  dropdownName="countryDropdown"
                />
              </div>
              <div className={`${styles.dropDownContainerDiv} `}>
                <Popover
                  placement="leftTop"
                  trigger="click"
                  title={""}
                  content={editStoreMsgContent()}
                  open={showCurrentConfigurationDialog}
                  onOpenChange={() => {
                    setConfigurationStatus("Add");
                    setShowCurrentConfigurationDialog(true);
                  }}
                >
                  <GenericButton
                    label="Add"
                    type="primary"
                    onClick={handleBtnClick}
                    style={buttonStyle}
                  />
                </Popover>
              </div>
            </div>
          </div>
        </div>

        <Divider
          style={{
            marginTop: "12px",
            marginBottom: "30px",
            height: "1px",
            backgroundColor: "#cccccc",
          }}
        ></Divider>

        <div className={styles.contentMainDiv}>
          <div className={styles.tablesContainer}>
            <div
              className={styles.smartphonesTableContainer}
              style={{ width: "100%" }}
            >
              {loading ? (
                <div className={styles.spinnerContainer}>
                  <Spin size="large" className="custom-spin" />
                </div>
              ) : (
                <CustomTable
                  columns={columns}
                  data={applicationFeaturesDetails}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default StoreConfiguration;
