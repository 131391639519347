import { getAppInsights } from '../../../services/TelemetryService';
import { GetUserName } from '../../../services/AuthUser';
import { fnBrowserDetect } from '../../utils/utils';
import { SeverityLevel } from '@microsoft/applicationinsights-web';

interface UserDetails {
  userName: string;
  userEmail: string;
}

const logAPIResponse = (data: Record<string, any>, description: string): void => {
  const userDetails: UserDetails[] | undefined = GetUserName();
  const appInsights = getAppInsights();

  appInsights?.trackTrace({
    message:
      // 'User Name: ' +
      // userDetails?.[0]?.userName +
      // ' User Email: ' +
      // userDetails?.[0]?.userEmail +
      // ' Browser: ' +
      // fnBrowserDetect() +
      description,
    severityLevel: SeverityLevel.Information,
    properties: {
      User_Name:
        userDetails?.[0]?.userName,
      User_Email:
        userDetails?.[0]?.userEmail,
      Browser:
        fnBrowserDetect(),
      data: data
    },

  });

};

const logUserLogin = (): void => {
  const userDetails: UserDetails[] | undefined = GetUserName();
  const appInsights = getAppInsights();
  appInsights?.trackEvent({
    name: `UserLoggedIn, User Name : ${userDetails?.[0]?.userName}, User Email : ${userDetails?.[0]?.userEmail}, Browser : ${fnBrowserDetect()}`, // Ensure this is not empty
    properties: {
      User_Name: userDetails?.[0]?.userName,
      User_Email: userDetails?.[0]?.userEmail,
      Browser: fnBrowserDetect(),
    },
  });

  // Track custom metric for user login count
  appInsights?.trackMetric({ name: 'UserLoginCount', average: 1 });
};

export { logAPIResponse, logUserLogin };
