import React, { useEffect, useState } from "react";
import { Col, Row, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import styles from "./Reports.module.css";
import { CardSection } from "../../components/CommonComponents/CardSection";
import CustomTable from "../../components/CommonComponents/Table";
import GenericButton from "../../components/CommonComponents/GenericButton";
import Header from "../../components/CommonComponents/Header";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";
import { GetAccessToken } from "../../services/AuthToken";
import { logAPIResponse } from "../../components/CommonUtils/utils/logs_Utils";

const Reports = () => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const navigate = useNavigate();
  const [metricesData, setMetricesData] = useState([]);
  const [applicationListData, setApplicationListData] = useState([]);
  const [selectedApplicationDetails, setSelectedApplicationDetails] =
    useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [metricsLoading, setMetricsLoading] = useState<boolean>(false);
  const [selectedApp, setSelectedApp] = useState<boolean>(false);

  const columns = [
    {
      title: "METRICS",
      dataIndex: "metrics",
      applicationId: "",
      width: 170,
    },
    {
      title: "METRICS NAME",
      dataIndex: "metricsName",
      applicationId: "metricsName",
      width: 250,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "CONFIGURABLE THRESHHOLD",
      dataIndex: "configurableThreshold",
      applicationId: "configurableThreshold",
      width: 220,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "CURRENT MEASURE",
      dataIndex: "currentMeasure",
      applicationId: "currentMeasure",
      width: 150,
      render: (text: any) => <span style={{ color: "#1E950B" }}>{text}</span>,
    },
    {
      title: "MEASURE TYPE",
      dataIndex: "measureType",
      applicationId: "measureType",
      width: 150,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "CONFIGURATION LEVEL",
      dataIndex: "configurationLevel",
      applicationId: "configurationLevel",
      width: 170,
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "MAPPED TO APPLICATION HEALTH",
      dataIndex: "applicationHealth",
      applicationId: "applicationHealth",
      width: 250,
      render: (text: any) => <span>{text}</span>,
    },
  ];

  const fetchApplicationList = async () => {
    setLoading(true)
    try {
      let token = await GetAccessToken();
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetApplicationList`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application-json",
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
          },
        }
      );
      if (!response.ok) {
        setLoading(false)
        logAPIResponse([], `Failed to fetch application list, /StoreReport/GetApplicationList.`)
        throw new Error("Network response was not ok");
      }
      setLoading(false)
      const data: any = await response.json();
      const newData = data.map((item: any) => ({ id: item?.applicationID, title: item?.applicationName, color: item?.alertColor, count: item?.alertCount }))
      setApplicationListData(newData);
    } catch (error) {
      setLoading(false)
      logAPIResponse([], `Failed to fetch application list, /StoreReport/GetApplicationList.${error}`)
    }
  };
  const fetchGetApplicationMetrices = async (applicationID: any) => {
    const params = new URLSearchParams({ applicationID });
    setMetricsLoading(true)
    setSelectedApp(true)
    try {
      let token = await GetAccessToken();
      const response = await fetch(
        `${environmentConfig.chatApiUrl}/StoreReport/GetApplicationMetrices?${params.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Ocp-Apim-Subscription-Key": environmentConfig.hapiSubscriptionKey
          },
        }
      );
      if (!response.ok) {
        setMetricsLoading(false)
        logAPIResponse([], `Failed to fetch application metrices, /StoreReport/GetApplicationMetrices.`)
        throw new Error("Network response was not ok");
      }
      setMetricsLoading(false)
      const data: any = await response.json();
      setMetricesData(data);
    } catch (error) {
      setMetricsLoading(false)
      logAPIResponse([], `Failed to fetch application metrices, /StoreReport/GetApplicationMetrices.${error}`)
      console.error("Error while fetching Application metrices data:", error);
    }
  };

  useEffect(() => {
    fetchApplicationList();
  }, []);

  const handleCardClick = (value?: any) => {
    setSelectedApplicationDetails([value]);
    fetchGetApplicationMetrices(value?.id || "");
  };

  const buttonStyle = {
    backgroundColor: "#222222",
    color: "white",
    fontSize: "16px",
    width: "139px",
    height: "48px",
    borderRadius: 0,
  };

  const handleBtnClick = () => {
    if (selectedApplicationDetails[0]?.title === "CUSTOMER COUNTER") {
      navigate("/customer-counter");
    }
    if (selectedApplicationDetails[0]?.title === "STORE SMARTPHONE") {
      navigate("/STORESMARTPHONE");
    }
    if (selectedApplicationDetails[0]?.title === "INSTORE CUST CARE - PROD") {
      navigate("/INSTORECUSTCARE-PROD");
    }
    if (selectedApplicationDetails[0]?.title === "STORE SCANNER SYSTEMS (MSS SERVICES)") {
      navigate("/store-scanner-systems");
    }
  };

  return (
    <>
      <Header pageTitle="Business Command Center" />
      <div className={styles.mainDiv}>
        <div className={styles.routePageTitleWrapper}>
          <a className={styles.breadCrumbsLink} style={{ paddingLeft: "0px" }} onClick={() => navigate("/")}>Home</a>
          <a>&gt;</a>
          <a className={styles.breadCrumbsLink} style={{ textDecoration: "underline", cursor: "auto" }}>Report</a>
        </div>
        <div className={styles.pageTitle}>Report</div>
        <div className={styles.devider} />
        {loading ? <div className={styles.spinnerContainer}><Spin size="large" className="custom-spin" /></div> : <div>
          <div
            style={{
              marginBottom: "15px",
              width: "100%",
            }}
          >
            <Row gutter={[16, 16]} >
              {applicationListData?.map((item: any) => {
                return (
                  <Col xs={24} sm={12} md={8} lg={6} key={item.applicationID}>
                    <div style={{ width: "auto", cursor: "pointer" }}>
                      <CardSection handleClick={handleCardClick} item={item} />
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
          {metricsLoading ? <div className={styles.spinnerContainer}><Spin size="large" className="custom-spin" /></div> : selectedApp && <div>
            <CustomTable columns={columns} data={metricesData} />
          </div>}
          {metricesData?.length > 0 && <div className={styles?.alignViewDetailsBtn}>
            <GenericButton
              label="View in details"
              type="primary"
              onClick={handleBtnClick}
              style={buttonStyle}
            />
          </div>}
        </div>}
      </div>
    </>
  );
};
export default Reports;
